import React, { useCallback } from 'react'
import { ReactComponent as Glossary } from '../../assets/glossary.svg'
import { ReactComponent as Legal } from '../../assets/scale.svg'
import { ReactComponent as FAQ } from '../../assets/questions.svg'

import { FooterContainer, FooterLink, FooterNav } from './Footer.style'
import { useCMSState } from '../../hooks'
import Feedback from '../Feedback/Feedback'

// @ts-ignore
const FooterTextLink = ({ value, text, icon, onClick, last }) => {
  const handleClick = useCallback(() => {
    if (onClick && value) {
      onClick(value)
    }
  }, [onClick, value])

  return (
    <React.Fragment>
      <FooterLink
        onClick={handleClick}
        data-test-id={`bottom-container_nav-${value}}`}
      >
        {icon} {text}
      </FooterLink>
      {!last && <span className='separator' />}
    </React.Fragment>
  )
}

const links = [
  // eslint-disable-next-line react/jsx-pascal-case
  { value: 'faq', text: 'FAQs', icon: <FAQ /> },
  { value: 'glossary', text: 'Glossary', icon: <Glossary /> },
  { value: 'legal', text: 'Legal', icon: <Legal /> }
]

const Footer: React.FC = () => {
  const { core, widget } = useCMSState()
  const { articleOptions } = widget
  const onLinkClick = useCallback(
    (value) => {
      window.open(`${core.ui}/documentation/${value}`, '_about')
    },
    [core.ui]
  )
  const hasFeedback = Boolean(articleOptions?.id)
  return (
    <FooterContainer data-test-id='bottom-container' hasFeedback={hasFeedback}>
      {hasFeedback && <Feedback />}
      <FooterNav data-test-id='bottom-container_nav'>
        {links.map((l, index) => (
          <FooterTextLink
            key={l.value}
            onClick={onLinkClick}
            last={index === links.length - 1}
            {...l}
          />
        ))}
      </FooterNav>
    </FooterContainer>
  )
}

export default Footer
