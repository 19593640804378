import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Spinner } from 'c2-uikit'
import SearchResult from './SearchResult/SearchResult'
import { ISearchResponse, ISearchResult } from '../../domain'
import { SearchContainer, TopContainer } from './SearchList.styles'

type SearchListProps = {
  paging: ISearchResponse['pagination']
  loading?: boolean
  setSize: (num: number) => void
  size: number
  total: number
  groups: string[]
  type: 'widget' | 'full'
  items: { [key: string]: ISearchResult[] }
  style: any
  scrollableTarget: string
}

const SearchList: React.FC<SearchListProps> = ({
  paging,
  loading,
  setSize,
  size,
  total,
  groups,
  items,
  type,
  scrollableTarget,
  style
}) => {
  return (
    <SearchContainer
      data-test-id='cms-search-list'
      scrollableNodeProps={{ id: 'scrollable-search-content' }}
      style={style}
    >
      <TopContainer data-test-id='cms-search-list-top'>
        {paging.rowCount > 0 && (
          <div className='info'>{paging.rowCount} results found</div>
        )}
        {!loading && paging.rowCount === 0 && (
          <div className='info'>No results found</div>
        )}
      </TopContainer>
      <InfiniteScroll
        scrollThreshold='100px'
        dataLength={total}
        next={() => {
          setSize(size + 1)
        }}
        hasMore={total < paging.rowCount}
        loader={
          <div className='loading'>
            <Spinner
              size='small'
              borderRadius='2px'
              data-test-id='spinner'
              color='var(--cc-main)'
            />
          </div>
        }
        scrollableTarget={scrollableTarget || 'scrollable-search-content'}
      >
        {groups &&
          groups.map((g: string, i: number) => (
            <div
              className='search-group'
              data-test-id='cms-search-list-group-item'
              key={`group-${g}-${i}`}
            >
              <h3>{g}</h3>
              {items[g].map((item: ISearchResult, i: number) => (
                <SearchResult key={i} {...item} type={type} />
              ))}
            </div>
          ))}
      </InfiniteScroll>
    </SearchContainer>
  )
}

export default SearchList
