import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  width: 100%;
  min-width: 100%;
  height: calc(100vh - 254px);
  z-index: 1;

  /* ckeditor5-image/theme/imageresize.css */
  .ck-content .image.image_resized {
    max-width: 100%;
    display: block;
    box-sizing: border-box;
  }

  /* ckeditor5-image/theme/imageresize.css */
  .ck-content .image.image_resized img {
    width: 100%;
  }

  /* ckeditor5-image/theme/imageresize.css */
  .ck-content .image.image_resized > figcaption {
    display: block;
  }

  /* ckeditor5-basic-styles/theme/code.css */
  .ck-content code {
    background-color: hsla(0, 0%, 78%, 0.3);
    padding: 0.15em;
    border-radius: 2px;
  }

  .ck-content u {
    display: inline-block;
  }

  /* ckeditor5-image/theme/image.css */
  .ck-content .image {
    display: block;
    clear: both;
    text-align: center;
    margin: 16px 0;
    z-index: 1;
    max-width: 360px;
  }

  /* ckeditor5-image/theme/image.css */
  .ck-content blockquote > p {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .ck-content .image > img {
    display: block;
    max-width: 100%;
    min-width: 100px;
    z-index: 1;
  }

  /* ckeditor5-image/theme/imagestyle.css */
  @media (max-width: 1100px) {
    .ck-content .image-style-side {
      float: unset !important;
    }
  }

  .ck-content .image-style-side,
  .ck-content .image-style-align-left,
  .ck-content .image-style-align-right {
    max-width: 50% !important;
  }

  /* ckeditor5-image/theme/imagestyle.css */
  .ck-content .image.image-style-side {
    float: right;
    margin-top: 8px;
    margin-left: var(--ck-image-style-spacing);
  }

  /* ckeditor5-image/theme/imagestyle.css */
  .ck-content .image-style-align-left {
    float: left;
    margin-right: var(--ck-image-style-spacing);
  }

  /* ckeditor5-image/theme/imagestyle.css */
  .ck-content .image-style-align-center {
    margin-left: auto;
    margin-right: auto;
  }

  /* ckeditor5-image/theme/imagestyle.css */
  .ck-content .image-style-align-right {
    float: right;
    margin-left: var(--ck-image-style-spacing);
  }

  /* ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break {
    position: relative;
    clear: both;
    padding: 16px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break::after {
    content: '';
    position: absolute;
    width: 100%;
  }

  /* ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break__label {
    position: relative;
    z-index: 1;
    padding: 0.3em 0.6em;
    display: block;
    text-transform: uppercase;
    border: 1px solid hsl(0, 0%, 77%);
    border-radius: 2px;
    font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
    font-size: 0.75em;
    font-weight: bold;
    color: hsl(0, 0%, 20%);
    background: #fff;
    box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* ckeditor5-block-quote/theme/blockquote.css */
  .ck-content blockquote {
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    border-left: solid 5px hsl(0, 0%, 80%);
    max-width: 280px;
  }

  /* ckeditor5-block-quote/theme/blockquote.css */
  .ck-content[dir='rtl'] blockquote {
    border-left: 0;
    border-right: solid 5px hsl(0, 0%, 80%);
  }

  /* ckeditor5-media-embed/theme/mediaembed.css */
  .ck-content .media {
    clear: both;
    margin: 1em 0;
    display: block;
    min-width: 15em;
  }

  /* ckeditor5-table/theme/table.css */
  .ck-content .table {
    margin: 8px 0 12px 0;
    display: table;
    float: unset !important;
  }

  /* ckeditor5-table/theme/table.css */
  .ck-content .table table {
    border-collapse: collapse;
    border-spacing: 0;
    height: 100%;
    border: 1px solid #e3e3e3;
  }

  /* ckeditor5-table/theme/table.css */
  .ck-content .table table td,
  .ck-content .table table th {
    min-width: 2em;
    padding: 16px !important;
    color: #525252;
    border-color: hsl(0, 0%, 75%);
  }

  /* ckeditor5-table/theme/table.css */
  .ck-content .table table tbody > tr:not(:first-child):nth-child(odd) > td {
    background: #fafafa;
  }

  .ck-content .table table tbody > tr > td {
    padding: 16px;
  }

  .ck-content .table table tbody > tr:first-child > td {
    padding: 20px 16px;
    font-weight: 500;
    font-size: 14px;
    color: #676767;
    background: #f2f2f2;
  }

  /* ckeditor5-list/theme/todolist.css */
  .ck-content .todo-list {
    list-style: none;
  }

  /* ckeditor5-list/theme/todolist.css */
  .ck-content .todo-list li {
    margin-bottom: 5px;
  }

  /* ckeditor5-list/theme/todolist.css */
  .ck-content .todo-list li .todo-list {
    margin-top: 5px;
  }

  /* ckeditor5-list/theme/todolist.css */
  .ck-content .todo-list .todo-list__label > input {
    -webkit-appearance: none;
    display: inline-block;
    position: relative;
    width: var(--ck-todo-list-checkmark-size);
    height: var(--ck-todo-list-checkmark-size);
    vertical-align: middle;
    border: 0;
    left: -25px;
    margin-right: -15px;
    right: 0;
    margin-left: 0;
  }

  /* ckeditor5-list/theme/todolist.css */
  .ck-content .todo-list .todo-list__label > input::before {
    display: block;
    position: absolute;
    box-sizing: border-box;
    content: '';
    width: 100%;
    height: 100%;
    border: 1px solid hsl(0, 0%, 20%);
    border-radius: 2px;
    transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background,
      250ms ease-in-out border;
  }

  /* ckeditor5-list/theme/todolist.css */
  .ck-content .todo-list .todo-list__label > input::after {
    display: block;
    position: absolute;
    box-sizing: content-box;
    pointer-events: none;
    content: '';
    left: calc(var(--ck-todo-list-checkmark-size) / 3);
    top: calc(var(--ck-todo-list-checkmark-size) / 5.3);
    width: calc(var(--ck-todo-list-checkmark-size) / 5.3);
    height: calc(var(--ck-todo-list-checkmark-size) / 2.6);
    border-style: solid;
    border-color: transparent;
    border-width: 0 calc(var(--ck-todo-list-checkmark-size) / 8)
      calc(var(--ck-todo-list-checkmark-size) / 8) 0;
    transform: rotate(45deg);
  }

  /* ckeditor5-list/theme/todolist.css */
  .ck-content .todo-list .todo-list__label > input[checked]::before {
    background: hsl(126, 64%, 41%);
    border-color: hsl(126, 64%, 41%);
  }

  /* ckeditor5-list/theme/todolist.css */
  .ck-content .todo-list .todo-list__label > input[checked]::after {
    border-color: hsl(0, 0%, 100%);
  }

  /* ckeditor5-list/theme/todolist.css */
  .ck-content .todo-list .todo-list__label .todo-list__label__description {
    vertical-align: middle;
  }

  /* ckeditor5-image/theme/imagecaption.css */
  .ck-content .image > figcaption {
    caption-side: bottom;
    word-break: break-word;
    color: var(--cc-text-dark);
    background-color: hsl(0, 0%, 97%);
    padding: 0.6em;
    font-size: 11px;
    text-align: center;
  }

  /* ckeditor5-highlight/theme/highlight.css */
  .ck-content .marker-yellow {
    background-color: var(--ck-highlight-marker-yellow);
  }

  /* ckeditor5-highlight/theme/highlight.css */
  .ck-content .marker-green {
    background-color: var(--ck-highlight-marker-green);
  }

  /* ckeditor5-highlight/theme/highlight.css */
  .ck-content .marker-pink {
    background-color: var(--ck-highlight-marker-pink);
  }

  /* ckeditor5-highlight/theme/highlight.css */
  .ck-content .marker-blue {
    background-color: var(--ck-highlight-marker-blue);
  }

  /* ckeditor5-highlight/theme/highlight.css */
  .ck-content .pen-red {
    color: var(--ck-highlight-pen-red);
    background-color: transparent;
  }

  /* ckeditor5-highlight/theme/highlight.css */
  .ck-content .pen-green {
    color: var(--ck-highlight-pen-green);
    background-color: transparent;
  }

  /* ckeditor5-horizontal-line/theme/horizontalline.css */
  .ck-content hr {
    border-width: 1px 0 0;
    border-style: solid;
    border-color: hsl(0, 0%, 37%);
    margin: 0;
  }

  /* ckeditor5-code-block/theme/codeblock.css */
  .ck-content pre {
    padding: 1em;
    color: #353535;
    background: hsla(0, 0%, 78%, 0.3);
    border: 1px solid hsl(0, 0%, 77%);
    border-radius: 2px;
    text-align: left;
    direction: ltr;
    tab-size: 4;
    white-space: pre-wrap;
    font-style: normal;
    min-width: 200px;
  }

  /* ckeditor5-code-block/theme/codeblock.css */
  .ck-content pre code {
    background: unset;
    padding: 0;
    border-radius: 0;
  }

  .ck-content a {
    color: var(--cc-main);
    text-decoration: none;
    background-color: transparent;
  }

  .ck-content ul {
    padding-inline-start: 16px;
    margin-bottom: 0;
    margin-top: 14px;
    margin-left: 20px;
  }

  .ck-content ul > li {
    font-size: 14px;
    line-height: 1.5;
    font-family: Roboto, sans-serif;
    padding-bottom: 10px;
    color: var(--cc-text-dark);
  }

  .ck-content blockquote {
    width: 100%;
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin: 0 0 1rem;
    font-style: italic;
    border-left: 5px solid var(--cc-separator);
    color: var(--cc-text-dark);
  }

  .ck-content h2 {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
    margin: 8px 0;
  }

  .ck-content h1 {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    margin: 8px 0 20px 0;
    color: var(--cc-text-darker);
  }

  .ck-content h3 {
    margin: 8px 0;
    font-weight: 400;
    font-size: 14px;
  }

  .ck-content h2 + h1 {
    margin-top: 0;
  }

  .cms-content__header {
    background: white;
    width: 100vw;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding: 12px 48px;
    height: 100px;
    font-size: 18px;
    color: var(--cc-text-dark);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .ck-content p + .cms-content__box {
    margin-top: 0;
  }

  .cms-content__text-with-image {
    position: relative;
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    text-align: left;
    color: var(--cc-text-dark);
  }

  .cms-content__text-with-image figure {
    margin-left: 40px;
    margin-right: 0;
  }

  .cms-content__box {
    min-width: 300px;
    max-width: 300px;
    margin-top: 20px;
    padding: 8px 16px 16px 16px;
    background-color: var(--cc-bg-light);
    color: var(--cc-text-dark);
    z-index: -1;
  }

  .ck-content p,
  .ck-content span {
    font-family: Roboto, sans-serif !important;
    font-size: 14px;
    color: var(--cc-text-dark);
    line-height: 1.5;
  }
  .ck-content .mjx-chtml span {
    line-height: 0px;
    font-size: unset;
  }
  .ck-content p,
  .ck-content p > span,
  .ck-content li > span,
  .ck-content .table > table tr > td span,
  .ck-content .table > table tr > td p {
    color: var(--cc-text-dark) !important;
  }

  .ck-content i,
  .ck-content span {
    color: var(--cc-text-dark);
  }
  .ck-content a span,
  .ck-content a i {
    color: var(--cc-main);
    display: inline-block;
  }
  .ck-content a i {
    margin-right: 1px;
  }
  .ck-content p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .cms-content__box p {
    font-size: 14px;
    color: var(--cc-text-dark);
  }

  ul,
  ol {
    padding-inline-start: 16px;
    margin-bottom: 0;
    margin-top: 14px;
    margin-left: 20px;
  }

  .ck-content ul > li,
  .ck-content ol > li {
    font-size: 14px;
    margin-left: 0 !important;
    font-family: Roboto, sans-serif;
    padding-bottom: 6px;
    line-height: 1.5;
    color: var(--cc-text-dark);
  }

  .ck-content > div > h1:first-child {
    font-size: 14px;
    max-width: calc(100% - 120px);
    line-height: 23px;
    font-weight: 600;
  }

  .cms-content__box-title {
    margin: 8px 0;
    font-weight: 500;
    color: #383838;
    font-size: 14px;
  }

  .cms-content__divider {
    border-top: 2px solid var(--cc-main);
    margin: 8px 0;
    width: 100%;
    height: 2px;
  }

  .cms-content__box {
  }

  .ck-content figure.table,
  .ck-content figure.table > table {
  }

  .ck-content figure.table {
    width: 100% !important;
    max-width: 900px;
    display: flex;
    height: auto !important;
    margin: 20px auto;
    overflow-x: auto;
  }

  .ck-content .table table {
    white-space: nowrap;
    height: auto !important;
  }

  .ck-content .table table tbody,
  .ck-content .table table tr {
  }

  .ck-content .table table tr > td {
    padding: 8px;
    vertical-align: top !important;
  }

  .ck-content .table table tr > td h2 mark,
  .ck-content .table table tr > td h2 span mark,
  .ck-content .table table tr > td h2 span {
    margin: 0;
  }

  .ck-content .table table:not(.font-sm) tr > td p,
  .ck-content .table table:not(.font-sm) tr > td p mark,
  .ck-content .table table:not(.font-sm) tr > td p span mark,
  .ck-content .table table:not(.font-sm) tr > td p span {
    font-size: 14px !important;
  }

  .ck-content .table table {
    border: 1px solid #dddddd !important;
  }
  .ck-content .table table tr > td {
    border: 1px solid #dddddd !important;
  }

  .ck-content p + blockquote,
  .ck-content ul + blockquote,
  .ck-content ol + blockquote,
  .ck-content p + .table {
    margin-top: 20px;
  }

  .ck-content .table table tbody > tr > td,
  .ck-content .table table tbody > tr > td p,
  .ck-content .table table tbody > tr > td span {
    font-size: 14px !important;
    line-height: 1.5;
    white-space: pre-wrap;
  }

  .ck-content table .image,
  .ck-content table video,
  .ck-content table p,
  .ck-content table span {
    min-width: auto;
    max-width: none;
    margin: 0;
  }

  .ck-content .image > img {
    max-width: 100%;
    width: auto;
    max-height: 500px;
  }

  .ck-content .image {
    text-align: left;
    max-width: 100%;
    margin-top: 30px;
  }

  .ck-content video {
    /* override other styles to make responsive */
    width: 100% !important;
    min-width: 300px;
    height: auto !important;
    background: #2a2a2a;
    box-sizing: border-box;
    border-radius: 5px;
    -moz-box-sizing: border-box;
    font-family: Arial, sans-serif;
    /* position: absolute; */
    padding: 0;
    z-index: 2;
    opacity: 1;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    -webkit-transition: opacity 0.3s ease-in;
    transition: opacity 0.3s ease-in;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    margin: 20px auto 30px auto;
  }

  .ck-content video,
  .ck-content p {
    min-width: 300px;
    max-width: 300px;
  }

  @media print {
    html,
    body {
      height: 99%;
      margin: 0 !important;
      padding: 0 !important;
    }
    .ck-content > *:last-child {
      page-break-after: auto;
    }

    .App {
      position: relative;
      width: 210mm;
    }

    .cms-content__box {
    }

    video {
      display: none;
    }

    figure.media {
      display: none;
    }

    .ck-content .cms-content__box {
      max-width: 200mm;
    }

    .ck-content figure.image {
      max-width: 210mm;
    }
    .ck-content figure img {
      width: auto;
      max-height: 100mm;
    }

    .ck-content figure.table {
      max-width: 208mm;
    }
    .ck-content figure.table,
    .ck-content figure.table table {
      overflow-x: inherit;
      white-space: inherit;
    }

    .ck-content table .cms-content__box {
      max-width: none;
    }

    .ck-content .image,
    .ck-content video,
    .ck-content p {
      max-width: 200mm;
    }

    .cms-content__box p {
      font-size: 11pt;
      padding: 0 12px;
    }

    .ck-content p,
    span {
      font-size: 11pt;
    }

    .ck-content ul > li,
    ol > li {
      font-size: 11pt;
    }

    .ck-content video {
      display: none;
    }

    .ck-content .image > img {
      min-width: 30mm;
      max-width: 100%;
      width: auto;
    }

    /* ckeditor5-page-break/theme/pagebreak.css */
    .ck-content .page-break {
      padding: 0;
    }

    /* ckeditor5-page-break/theme/pagebreak.css */
    .ck-content .page-break::after {
      display: none;
    }
    .ck-content .image-style-side img,
    .ck-content .image-style-side figcaption {
      max-width: 80mm !important;
      margin: 0 !important;
    }

    .ck-content .image-style-side,
    .ck-content .image-style-align-left,
    .ck-content .image-style-align-center,
    .ck-content .image-style-align-right {
      padding: 0 20px 0 0;
      float: none;
      display: inline-block;
      vertical-align: middle;
      max-width: none !important;
      margin: 16px;
    }
  }
  .image > img {
    max-height: none;
    width: auto;
    margin: 0 auto;
  }

  .img-shimmer {
    margin: 32px auto;
  }

  .preview-overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5000;
    background: rgba(0, 0, 0, 0.4);

    .preview {
      position: relative;
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      max-height: calc(100vh - 150px);
      max-width: calc(100vw - 150px);
      min-width: 50vw;
      user-select: none;
      box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.4);

      .close {
        transform: scale(1.5);
        position: absolute;
        cursor: pointer;
        right: -20px;
        top: -20px;

        &:hover {
          g > use {
            stroke-width: 3px;
            transition: all 0.2s ease;
            fill: rgba(255, 255, 255, 0.9);
          }
        }
      }

      img {
        max-height: calc(100vh - 190px);
        max-width: calc(100vw - 190px);
        height: auto;
      }
    }
  }
`

export const ArticleContainer = styled.div`
  position: relative;
  font-size: 14px;
  font-family: Roboto, sans-serif;
  text-align: left;
  width: 100%;
  height: 100%;
  overflow: auto;

  .with-panel {
    display: flex;
    background: #f2f2f2;
    align-items: flex-start;

    .article {
      display: flex;
      flex-direction: column;
      position: relative;
      min-width: 386px;
      max-width: 386px;
      margin: 16px 16px 16px 16px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      min-height: 50vh;
      background-color: white;

      &.no-pdf {
        .ck-content {
          padding-top: 40px;
        }

        .ck-content div > h1:first-child {
          max-width: 100%;
        }
      }

      .article-footer {
        flex: 2;

        display: flex;
        min-width: 386px;
        max-width: 386px;
        width: 386px;
        align-items: center;
        justify-content: flex-end;
        flex-direction: column;
      }

      .swagger-ui {
        padding-top: 25px;
      }
    }

    .article-controls {
      height: calc(100vh - 148px);
      min-height: calc(100vh - 148px);
      display: flex;
      align-items: center;
      min-width: 280px;
    }
  }

  figure.table {
    -ms-overflow-style: scrollbar;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
      background-color: #f8f8f8;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: 3px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
  }

  &.treeMap {
    background-color: white;
    max-width: none;

    .article {
      box-shadow: none;
      max-width: none;
    }

    .article-footer {
      max-width: none;
      width: 100%;
    }
  }

  .ck-content-container {
    padding: 12px 30px 20px 30px;

    background-color: white;
    z-index: 1;
    overflow: auto;
  }

  .disclaimer {
    page-break-before: always;
    page-break-after: avoid;

    p {
      font-size: 9pt;
      text-align: justify;
      color: #525252;
      line-height: 1.5;
    }
  }

  .loading-overlay {
    width: 360px;
    position: absolute;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out;

    .spinner {
      margin: 0 auto;
    }
  }

  &.loading {
    overflow: hidden;

    .loading-overlay {
      opacity: 1;
      visibility: visible;
      transition: none;
    }
  }
`

export const FooterText = styled.div`
  width: 100%;
  font-size: 12px;
  text-align: center;
  padding-bottom: 20px;
  color: #777777;
`

export const DownloadLink = styled.a<{ disabled?: boolean }>`
  display: flex;
  color: #0b74a3;
  position: absolute;
  right: 24px;
  top: 4px;
  z-index: 10;
  font-size: 13px;
  font-weight: 500;
  align-items: center;
  justify-content: flex-end;
  user-select: none;
  opacity: 0.8;
  height: 40px;

  ${(props) => props.disabled && `opacity: .5; cursor: not-allowed;`}
  svg {
    margin-right: 4px;

    path:last-child {
      fill: #0b74a3;
    }
  }

  &:hover {
    ${(props) => !props.disabled && `cursor: pointer; opacity: 1;`}
  }
`
