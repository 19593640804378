import * as React from 'react'
import { Action } from '../context/cms.actions'

export type Dispatch<T = any> = (action: Action<T>) => void

export const CMSDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
)

function useCMSDispatch() {
  const context = React.useContext(CMSDispatchContext)
  if (context === undefined) {
    throw new Error('useCMSDispatch must be used within a CMSProvider')
  }
  return context
}

export default useCMSDispatch
