import React, { useCallback, useEffect, useRef } from 'react'
import { useCMSDispatch, useCMSState } from '../../hooks'
import { ReactComponent as Close } from '../../assets/close.svg'
import {
  WIDGET_CLOSE,
  WIDGET_HOME,
  WIDGET_MINIMIZE,
  WIDGET_SEARCH,
  WIDGET_SEARCH_CHANGE
} from '../../context/cms.actions'
import { ReactComponent as Logo } from '../../assets/logo.svg'
import Input from '../Input/Input'
import { useSearch } from '../../hooks/apiHooks'
import ArticleRenderer from '../Article/Article'
import SearchList from '../SearchList/SearchList'
import Footer from '../Footer/Footer'

import ExternalLink from '../ExternalLink/ExternalLink'
import useArticleLink from '../../hooks/useArticleLink'
import useDebounce from '../../hooks/useDebounce'

import styles from './WidgetApp.style.css'
import { ie } from '../../utils'

const {
  widgetApp,
  widgetAppTopBar,
  widgetAppNoResults,
  widgetAppNoResults__visible,
  widgetAppTopSecondaryBar,
  widgetLogo,
  widgetActions,
  widgetSearchInput,
  minimize
} = styles

const Image: React.FC<{ src: string }> = React.memo(({ src }) => (
  <img src={src} alt='image' />
))

const WidgetApp: React.FC = () => {
  const inputRef = useRef<HTMLInputElement>(null)
  const { widget, core } = useCMSState()
  const { app, sendToCmsIFrame } = core
  const { search } = widget
  const dispatch = useCMSDispatch()
  const debouncedSearch = useDebounce(search, 1000)

  const handleHome = useCallback(() => {
    dispatch({ type: WIDGET_HOME, value: '' })
  }, [dispatch])

  const handleClose = useCallback(() => {
    dispatch({ type: WIDGET_CLOSE })
  }, [dispatch])

  const handleMinimize = useCallback(() => {
    dispatch({ type: WIDGET_MINIMIZE })
  }, [dispatch])

  const goSearch = useCallback(() => {
    dispatch({ type: WIDGET_SEARCH })
  }, [dispatch])

  const currentArticleLink = useArticleLink()

  const onEnter = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        dispatch({ type: WIDGET_SEARCH })
      }
    },
    [dispatch]
  )

  const {
    data,
    onChange,
    size,
    setSize,
    groups,
    items,
    total,
    paging,
    loading
  } = useSearch()

  const handleSearchChange = useCallback(
    (e) => {
      onChange(e)
      dispatch({ type: WIDGET_SEARCH_CHANGE, value: e.target.value })
    },
    [onChange, dispatch]
  )

  useEffect(() => {
    if (debouncedSearch) {
      sendToCmsIFrame({
        type: 'track',
        category: 'Widget',
        action: `${app}_widget-search`,
        label: debouncedSearch
      })
    }
  }, [debouncedSearch, sendToCmsIFrame])

  useEffect(() => {
    if (widget.state === 'main' && inputRef.current) {
      inputRef.current.focus()
    }
  }, [widget.state])

  return (
    <div className={widgetApp} data-test-id='widget-app'>
      <div className={widgetAppTopBar} data-test-id='widget-app-topbar'>
        <div className={widgetLogo}>
          <Logo
            className={ie() ? 'ie' : ''}
            data-test-id='widget-app-logo'
            onClick={handleHome}
          />{' '}
          Documentation
        </div>
        <div className={widgetActions} data-test-id='widget-app-topbar-actions'>
          <div
            className={minimize}
            onClick={handleMinimize}
            data-test-id='widget-app-topbar-minimize'
          />
          <Close onClick={handleClose} data-test-id='widget-app-topbar-close' />
        </div>
      </div>

      <Input
        testId='widget-app-search-container'
        ref={inputRef}
        containerClassname={widgetSearchInput}
        loading={loading}
        placeholder='Search articles'
        onKeyDown={onEnter}
        addon='search'
        autoFocus
        onChange={handleSearchChange}
        value={search}
        type='text'
      />

      {widget.page === 'article' && (
        <React.Fragment>
          <div
            className={widgetAppTopSecondaryBar}
            data-test-id='widget-app-menu-bar'
          >
            <a className='cms-link' href={undefined} onClick={goSearch}>
              Back
            </a>
            <ExternalLink href={currentArticleLink}>
              Open in new tab
            </ExternalLink>
          </div>
          <ArticleRenderer />
        </React.Fragment>
      )}

      {widget.page === 'search' && (
        <React.Fragment>
          <div
            data-test-id='widget-app-search-screen'
            className={`${widgetAppNoResults} ${
              !data.length || !search ? widgetAppNoResults__visible : ''
            }`}
          >
            <span>
              {!loading && !search
                ? 'Welcome to the CyberCube Documentation Widget!'
                : ''}
            </span>
            <span>
              {loading
                ? 'Please wait...'
                : !search
                ? 'Start typing to search an article...'
                : 'No results found'}
            </span>
            <Image src={`${core.ui}/hero-illustration-1.png`} />
            {!loading && !search ? (
              <ExternalLink href={core.ui}>
                Open documentation in new tab
              </ExternalLink>
            ) : (
              <div style={{ height: 26, width: '100%' }} />
            )}
          </div>
          {!data.length || !search ? (
            <span />
          ) : (
            <SearchList
              groups={groups}
              items={items}
              paging={paging}
              total={total}
              scrollableTarget='scrollable-search-content'
              setSize={setSize}
              size={size}
              type='widget'
              loading={loading}
              style={{ flex: 1 }}
            />
          )}
        </React.Fragment>
      )}
      <Footer />
    </div>
  )
}

export default WidgetApp
