import * as React from 'react'
import { CmsState } from '../context/cms.state'

export const CMSStateContext = React.createContext<CmsState | undefined>(
  undefined
)

function useCMSState() {
  const context = React.useContext(CMSStateContext)
  if (context === undefined) {
    throw new Error('useCMSState must be used within a CMSProvider')
  }
  return context
}

export default useCMSState
