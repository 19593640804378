import React from 'react';
import { ButtonProps } from './Button.types';
import { StyledButton } from './Button.styled';

/**
 * This component extend base button interface HTMLButtonElement.
 */
const Button: React.FC<ButtonProps> = ({ children, ...props }) => {
    return (
        <StyledButton data-testid="button" {...props}>
            {children}
        </StyledButton>
    );
};

export default Button;
