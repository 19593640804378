import useSWR from 'swr'
import useCMSApi from '../useCMSApi'
import { AxiosResponse } from 'axios'
import { IArticleStats, IFeedback } from '../../domain'
import { useCallback, useMemo } from 'react'

export const getFeedbackStatsPath = (id: number) =>
  `/cms/feedbacks/stats?article=${id}`

type UseFeedbackType = (params: {
  articleId?: number
  onSuccess?: (p: any) => void
}) => {
  postFeedback: (feedback: IFeedback) => Promise<void>
  data: IArticleStats
  error?: boolean
  loading?: boolean
  revalidate: any
  mutate: any
}

export const DEFAULT_FEEDBACK_STATS = {
  id: 0,
  totalHelpful: 0,
  totalNotHelpful: 0,
  helpful: null
}

const useFeedback: UseFeedbackType = ({ articleId, onSuccess }) => {
  const api = useCMSApi()
  const { data, error, revalidate, mutate } = useSWR<
    AxiosResponse<{ body: IArticleStats }>
  >(getFeedbackStatsPath(articleId || 0), api, {
    isPaused: () => !articleId,
    revalidateOnFocus: false,
    onSuccess
  })

  const currentData = useMemo(
    () =>
      data ? data.data.body || DEFAULT_FEEDBACK_STATS : DEFAULT_FEEDBACK_STATS,
    [data]
  )

  const postFeedback = useCallback(
    async (feedback: IFeedback) => {
      await api.post('/cms/feedbacks', feedback)
    },
    [api]
  )
  return {
    data: currentData,
    error,
    mutate,
    loading: !data,
    revalidate,
    postFeedback
  }
}

export default useFeedback
