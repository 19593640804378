import { createGlobalStyle } from 'styled-components';
import RobotoRegularWoff from './Roboto-Regular.woff';
import RobotoRegularWof2 from './Roboto-Regular.woff2';
import RobotoMediumWoff from './Roboto-Medium.woff';
import RobotoMediumWoff2 from './Roboto-Medium.woff2';
import RobotoBoldWoff from './Roboto-Bold.woff';
import RobotoBoldWoff2 from './Roboto-Bold.woff2';

export default createGlobalStyle`
    @font-face {
        font-family: 'Roboto';
        src: local('Font Name'), local('FontName'),
        url(${RobotoRegularWof2}) format('woff2'),
        url(${RobotoRegularWoff}) format('woff');
    }

    @font-face {
        font-family: 'Roboto Medium';
        src: local('Font Name'), local('FontName'),
        url(${RobotoMediumWoff2}) format('woff2'),
        url(${RobotoMediumWoff}) format('woff');
    }

    @font-face {
        font-family: 'Roboto Bold';
        src: local('Font Name'), local('FontName'),
        url(${RobotoBoldWoff2}) format('woff2'),
        url(${RobotoBoldWoff}) format('woff');
    }
`;
