import React, { useCallback, useState } from 'react'
import { Spinner } from 'c2-uikit'
import Checkbox from '../Checkbox/Checkbox'
import { IFeedback } from '../../domain'
import { FeedbackModalStyled } from './Feedback.style'

const FeedbackModal: React.FC<{
  loading?: boolean
  onSubmit: any
  onCancel: any
}> = ({ loading, onSubmit, onCancel }) => {
  const [data, setData] = useState<Partial<IFeedback>>({
    comment: '',
    not_accurate: false,
    not_informative: false,
    not_relevant: false
  })

  const send = useCallback(
    async (e) => {
      e.preventDefault()
      onSubmit(data)
    },
    [data, onSubmit]
  )

  const cancel = useCallback(async () => {
    onCancel()
  }, [onCancel])

  const handleFieldChange = useCallback(
    ({ target }) => {
      setData({
        ...data,
        [target.name]: target.value
      })
    },
    [data]
  )

  const handleCBChange = useCallback(
    ({ target }) => {
      setData({
        ...data,
        [target.name]: target.checked
      })
    },
    [data]
  )

  return (
    <FeedbackModalStyled className='feedback-additional-info'>
      <form className='feedback-form' onSubmit={send}>
        <label className='title'>Please provide additional information</label>
        <Checkbox
          name='not_relevant'
          value={data.not_relevant}
          onChange={handleCBChange}
        >
          Not Relevant
        </Checkbox>
        <Checkbox
          name='not_accurate'
          value={data.not_accurate}
          onChange={handleCBChange}
        >
          Not Accurate
        </Checkbox>
        <Checkbox
          name='not_informative'
          value={data.not_informative}
          onChange={handleCBChange}
        >
          Not Enough Information
        </Checkbox>
        <textarea
          name='comment'
          placeholder='Write a comment (optional) ...'
          value={data.comment}
          onChange={handleFieldChange}
        />
        <div className='buttons'>
          <button type='button' onClick={cancel}>
            Cancel
          </button>
          <button type='submit'>
            {loading ? (
              <Spinner width='12px' height='12px' color='white' />
            ) : (
              'Submit'
            )}
          </button>
        </div>
      </form>
    </FeedbackModalStyled>
  )
}
export default FeedbackModal
