import React, { InputHTMLAttributes } from 'react';
import { StyledInput, Label } from './Input.styled';
import { InputProps } from './Input.types';

/**
 * This component extend base input interface HTMLInputElement.
 */
const Input: React.FC<InputProps> = ({ name, label, ...props }) => {
    return (
        <>
            <div>{label && <Label htmlFor={name}>{label}</Label>}</div>
            <StyledInput name={name} {...props} />
        </>
    );
};

export default Input;
