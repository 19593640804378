import { NotificationModel } from '../../domain'
import {
  NotificationAction,
  NOTIFICATIONS_LOAD,
  NOTIFICATIONS_MARK_ALL_SEEN,
  NOTIFICATIONS_MARK_SEEN
} from '../cms.actions'

export type NotificationState = {
  notificationsId?: number
  notificationsData?: any
  notificationsArticles: number[]
  notificationsCategories: number[]
  seenNotifications: { [key: string]: number[] }
  notifications: NotificationModel[]
}

const NotificationReducer = (
  state: NotificationState,
  action: NotificationAction<any>
) => {
  switch (action.type) {
    case NOTIFICATIONS_MARK_ALL_SEEN: {
      const username = sessionStorage.getItem('username') || '_'
      const localCache = action.value || {}
      const seenNotifications = localCache[username] || []
      return {
        ...state,
        seenNotifications: localCache,
        notifications: state.notifications.filter(
          (a: NotificationModel) => seenNotifications.indexOf(a.id) < 0
        )
      }
    }
    case NOTIFICATIONS_MARK_SEEN: {
      const username = sessionStorage.getItem('username') || '_'
      const notificationIds = action.value || []
      const localCacheStr = localStorage.getItem('seenNotifications') || '{}'
      const localCache = JSON.parse(localCacheStr) || {}
      localCache[username] = Array.from(
        new Set([...(localCache[username] || []), ...notificationIds])
      )
      localStorage.setItem('seenNotifications', JSON.stringify(localCache))
      return {
        ...state,
        seenNotifications: localCache,
        notifications: state.notifications.filter(
          (a: NotificationModel) =>
            (localCache[username] || []).indexOf(a.id) < 0
        )
      }
    }
    case NOTIFICATIONS_LOAD: {
      const username = sessionStorage.getItem('username') || '_'
      const {
        id,
        data,
        notifications,
        newArticles,
        newCategories
      } = action.value

      return {
        ...state,
        notificationsId: id,
        notificationsData: data,
        notificationsArticles: newArticles,
        notificationsCategories: newCategories,
        notifications: (notifications || []).filter(
          (a: NotificationModel) =>
            (state.seenNotifications[username] || []).indexOf(a.id) < 0
        )
      }
    }
    default: {
      return state
    }
  }
}

export default NotificationReducer
