import { useCallback, useMemo, useState } from 'react'
import useCMSApi from '../useCMSApi'
import { useSWRInfinite } from 'swr'
import { AxiosResponse } from 'axios'
import { ISearchResponse, ISearchResult } from '../../domain'
import { getContentModeParam } from '../../utils'
import { WIDGET_SEARCH } from '../../context/cms.actions'
import useCMSDispatch from '../useCMSDispatch'
import useDebounce from '../useDebounce'

export const getSearchPath = (
  query: string,
  offset: number = 0,
  limit: number = 10,
  details: boolean = false
) =>
  `/cms/categories/search?query=${query}${
    offset >= 0 ? `&offset=${offset}` : ''
  }${limit >= 0 ? `&limit=${limit}` : ''}
  ${details ? `&details=${details}` : ''}${getContentModeParam()}`

export const useSearch = (
  options = {
    debounce: 300,
    limit: 10,
    queryDefault: ''
  }
) => {
  const { limit, queryDefault } = options
  const [query, setQuery] = useState(queryDefault)
  const dispatch = useCMSDispatch()
  const api = useCMSApi()
  const debouncedQuery = useDebounce(query, options.debounce)

  // eslint-disable-next-line no-unused-vars
  const { data, error, size, setSize, revalidate } = useSWRInfinite<
    AxiosResponse<{ body: ISearchResponse; status: number }>
  >(
    (index, _) => {
      if (!debouncedQuery || debouncedQuery === '') return null

      return getSearchPath(debouncedQuery, index * limit, limit, true)
    },
    api,
    {
      revalidateOnFocus: false
    }
  )

  const onChange = useCallback(
    (e) => {
      dispatch({ type: WIDGET_SEARCH })
      setQuery(e.target.value)
    },
    [dispatch]
  )

  // @ts-ignore
  const [dataCollection, groups, items] = useMemo(() => {
    if (data && data.length) {
      const allItems = data.flatMap((d) => (d.data ? d.data.body.data : []))

      const groupedItems: {
        [key: string]: ISearchResult[]
      } = allItems.reduce((prev, curr) => {
        const group = curr.parentTitle || curr.catTitle || ''
        return {
          ...prev,
          [group]: prev[group] ? prev[group].concat(curr) : [curr]
        }
      }, {})
      return [data.map((d) => d.data), Object.keys(groupedItems), groupedItems]
    }
    return [[], [], {}]
  }, [data])

  const paging = useMemo(
    () =>
      dataCollection &&
      dataCollection.length &&
      dataCollection[dataCollection.length - 1]
        ? dataCollection[dataCollection.length - 1].body.pagination
        : ({} as any),
    [dataCollection]
  )

  const total = useMemo(() => Object.values(items).flatMap((i) => i).length, [
    items
  ])
  return {
    loading: Boolean(query) && !data && !error,
    groups: groups as string[],
    items,
    data: dataCollection,
    error,
    size,
    paging,
    total,
    setSize,
    revalidate,
    onChange,
    value: query || ''
  }
}

export default useSearch
