import React from 'react'
import { CheckboxContainer } from './Checkbox.style'

// TODO - move to c2-uikit

const Checkbox: React.FC<any> = ({ name, children, value, onChange }) => {
  return (
    <CheckboxContainer data-test-id={`checkbox-choice-${name}`}>
      {children}
      <input type='checkbox' name={name} checked={value} onChange={onChange} />
      <span className='checkmark' />
    </CheckboxContainer>
  )
}

export default Checkbox
