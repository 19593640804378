import { IArticle, ISearchResponse } from '../domain'
import combineReducers from 'react-combine-reducers'
import WidgetReducer from './reducers/widget.reducer'
import NotificationReducer, {
  NotificationState
} from './reducers/notification.reducer'
import { Reducer } from 'react'
import { CmsState } from './cms.state'
import { Action } from './cms.actions'

const localCacheStr = localStorage.getItem('seenNotifications') || '{}'
const seenNotifications = JSON.parse(localCacheStr) || {}
const NOTIFICATION_DEFAULT_STATE: NotificationState = {
  notifications: [],
  notificationsArticles: [],
  notificationsCategories: [],
  seenNotifications
}

const WIDGET_DEFAULT_STATE: WidgetState = {
  state: 'hidden',
  loading: true,
  page: 'search',
  search: ''
}

export type NotificationModel = {
  id: number
  link?: string
  text?: string
  linkText?: string
}

export type WidgetStateType = 'hidden' | 'main' | 'minimize'
export type WidgetPageType = 'home' | 'search' | 'article'

export type WidgetState = {
  state: WidgetStateType | string
  page: WidgetPageType | string
  search: string
  loading?: boolean
  articleOptions?: {
    id: number
    rootId?: number
    rootSlug?: string
    catId: number
    categorySlug: string
    articleSlug: string
  }
  articleData?: IArticle
  searchResponse?: ISearchResponse
}

export const [cmsReducer, initialState]: [
  Reducer<CmsState, Action<any>>,
  CmsState
] = combineReducers<Reducer<CmsState, Action<any>>>({
  core: [
    (state, _) => state,
    { api: '', ui: '', app: 'Unknown', sendToCmsIFrame: () => {} }
  ],
  widget: [WidgetReducer, WIDGET_DEFAULT_STATE],
  notification: [NotificationReducer, NOTIFICATION_DEFAULT_STATE]
})

export default [cmsReducer, initialState]
