import React, { useCallback, useEffect, useMemo } from 'react'
import { History } from 'history'
import { createApi } from '../api'
import { AxiosInstance } from 'axios'
import Notifications from '../components/Notifications/Notifications'
import { CMSStateContext } from '../hooks/useCMSState'
import { CMSApiContext } from '../hooks/useCMSApi'
import { CMSDispatchContext } from '../hooks/useCMSDispatch'
import { AppType, CmsState } from './cms.state'
import { cmsReducer, initialState } from './cms.reducer'
import { NOTIFICATIONS_MARK_ALL_SEEN } from './cms.actions'
import { useCMSEventTransfer } from '../hooks'

export type CMSProviderProps = {
  children: React.ReactNode
  disabled?: boolean
  enableNotifications?: boolean
  api?: string
  ui?: string
  history?: History
  app: AppType
  devMode?: boolean
}

const getDefaultBase = () => {
  const { host, protocol } = window.location
  let domain = host
  const domainParts = domain.split('.')
  domainParts.shift()
  domain = domainParts.join('.')

  const api = `${protocol}//api.docs.${domain}`
  const ui = `${protocol}//docs.${domain}`

  return { api, ui }
}
const { api: apiDefault, ui: uiDefault } = getDefaultBase()

const CMSProvider = ({
  children,
  disabled,
  enableNotifications,
  api,
  app,
  ui,
  history,
  devMode
}: CMSProviderProps) => {
  const uiURL = !devMode ? uiDefault : ui || uiDefault
  const { frame, send } = useCMSEventTransfer({ ui: uiURL, app })

  const coreState: CmsState['core'] = useMemo(() => {
    return {
      ui: uiURL,
      api: !devMode ? apiDefault : api || apiDefault,
      app: app || '',
      history,
      sendToCmsIFrame: send
    }
  }, [uiURL, send, history, app, devMode, api])

  const apiInstance: AxiosInstance = useMemo(() => createApi(coreState.api), [
    coreState.api
  ])

  const [state, dispatch] = React.useReducer(cmsReducer, {
    ...initialState,
    core: coreState
  })

  const listenStorage = useCallback(
    ({ key, newValue }) => {
      if (key === 'seenNotifications') {
        dispatch({
          type: NOTIFICATIONS_MARK_ALL_SEEN,
          value: JSON.parse(newValue)
        })
      }
    },
    [dispatch]
  )

  useEffect(() => {
    window.addEventListener('storage', listenStorage)
    return () => {
      window.removeEventListener('storage', listenStorage)
    }
  }, [listenStorage])

  const render = useMemo(
    () =>
      disabled ? (
        children
      ) : (
        <CMSStateContext.Provider value={state}>
          <CMSApiContext.Provider value={apiInstance}>
            <CMSDispatchContext.Provider value={dispatch}>
              {frame}
              {enableNotifications && <Notifications />}
              {children}
            </CMSDispatchContext.Provider>
          </CMSApiContext.Provider>
        </CMSStateContext.Provider>
      ),
    [
      frame,
      disabled,
      children,
      enableNotifications,
      state,
      apiInstance,
      dispatch
    ]
  )

  return <React.Fragment>{render}</React.Fragment>
}

export default CMSProvider
