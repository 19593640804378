import React, { FC } from 'react';
import Icons from './index';
import { IconProps } from './Icon.types';

/**
 * This component extend base svg interface SVGSVGElement.
 */

const Icon: FC<IconProps> = ({ children, ...props }) => {
    if (!props.icon || !Icons[props.icon]) {
        return null;
    }

    const Svg = Icons[props.icon];

    return (
        <Svg
            width={props.size || '24'}
            height={props.size || '24'}
            preserveAspectRatio="xMidYMid meet"
            viewBox={props.viewBox || '0 0 24 24'}
            {...props}
        >
            {children}
        </Svg>
    );
};

export default Icon;
