import styled from 'styled-components'

export const FeedbackContainer = styled.div<{
  helpful?: number | null
  shown?: boolean
}>`
  height: 60px;
  padding: 0 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-top: 1px solid #e0e0e0;
  visibility: hidden;
  opacity: 0;

  ${({ shown }) =>
    shown &&
    `
      opacity: 1;
      visibility: visible;
  `}
  .line {
    background: #ebebeb;
    flex: 1;
    height: 4px;

    &.positive {
      margin-left: 8px;
      margin-right: 2px;
      background: #2ca66b;
    }

    &.negative {
      margin-left: 2px;
      margin-right: 8px;
      background: #f07171;
    }
  }

  .number {
    font-size: 12px;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    color: #9b9c9e;
    margin: 0 8px;
  }

  svg path {
    cursor: pointer;
    stroke: #000000;
    stroke-width: 1px;
    fill: #ebebeb;
    transition: fill 0.3s ease-in-out;
  }

  svg:first-child:hover path {
    fill: #2ca66b;
  }

  svg:last-child:hover path {
    fill: #f07171;
  }

  ${({ helpful }) =>
    helpful === 1
      ? `
    svg:first-child path {
      fill: #2ca66b;
    }
  `
      : helpful === 0 &&
        `
    svg:last-child path {
      fill: #f07171;
    }

  `}
`

export const FeedbackModalStyled = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 32px;
  color: var(--cc-text-dark);
  font-weight: 500;
  min-width: 200px;
  position: fixed;
  bottom: 63px;
  box-sizing: border-box;
  z-index: 200;
  background: white;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 420px;
  right: 0;
  min-height: 190px;

  .feedback-form {
    padding: 0 24px;
    display: flex;
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
    margin: 0 auto;
    > label.title {
      font-size: 14px;
      margin-bottom: 18px;
      line-height: 1.5;
      font-weight: bold;
    }

    > * {
      min-width: 180px;
    }
  }

  .form__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    > span {
      display: flex;
      width: 70px;
      align-items: flex-start;
      justify-content: space-between;
      margin: 12px 0;

      div {
        display: flex;
        align-items: center;
        flex-direction: column;

        b {
          font-weight: 500;
          margin-top: 8px;
          font-size: 11px;
        }

        svg {
          stroke-width: 1px;
          stroke: #cacaca;
          cursor: pointer;

          &[data-disabled='true'] {
            opacity: 0.7;
          }
        }

        &:last-child svg.active {
          fill: #e7414e;
          stroke: #b5343e;
        }

        &:first-child svg {
          stroke-width: 1px;
          stroke: #cacaca;

          &.active {
            fill: #00a690;
            stroke: #00695b;
          }
        }
      }
    }
  }

  textarea {
    background: white;
    padding: 8px;
    margin: 14px 0;
    max-height: 200px;
    font-size: 12px;
    width: 163px;
    border: 1px solid #80808033;
  }

  .buttons {
    display: flex;
    width: 100%;

    button {
      background: #00a690;
      color: white;
      min-width: 100px;
      font-size: 12px;
      padding: 8px 12px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      outline: none;

      .spinner {
        width: 12px;
        height: 12px;
        min-width: 12px;
        min-height: 12px;
        border-width: 2px;
        margin-right: 6px;
      }

      &:hover:not(:disabled) {
        cursor: pointer;
        opacity: 0.85;
        transition: opacity 0.25s ease-in-out;
      }

      &:disabled {
        cursor: not-allowed;
        background: gray;
      }

      &:first-child {
        margin-right: 20px;
        background: var(--cc-main);
        color: white;
      }
    }
  }

  .comment-control {
    margin-top: 12px;
    display: flex;
    font-size: 14px;
    align-items: center;
    color: var(--cc-text-dark);
    font-weight: 500;

    svg {
      margin-right: 12px;
    }

    &:hover {
      cursor: pointer;
      opacity: 0.9;
      transition: opacity 0.2s ease-in-out;
    }
  }
`
