import React, { Component } from 'react';
import { OverlayProps } from './Overlay.types';
import {
    OverlayContainer,
    OverlayOverlap,
    OverlayContentWrapper,
} from './Overlay.styled';

class Overlay extends Component<OverlayProps, {}> {
    render() {
        const {
            active,
            children,
            content,
            overlapColor,
            offsetFormTop,
            opacity,
            // fixed,
            // fullHeight
        } = this.props;

        if (!active) {
            return <>{children}</>;
        }

        // const wrapperClassName = `overlay__wrapper ${fixed && 'overlay__fixed'} ${fullHeight && 'overlay__full-height'}`

        return (
            <OverlayContainer
            //   className={wrapperClassName}
            >
                {active && (
                    <OverlayOverlap
                        overlapColor={overlapColor}
                        opacity={opacity}
                    >
                        <OverlayContentWrapper offsetFormTop={offsetFormTop}>
                            {content}
                        </OverlayContentWrapper>
                    </OverlayOverlap>
                )}
                {children}
            </OverlayContainer>
        );
    }
}

export default Overlay;
