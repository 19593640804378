import SimpleBar from 'simplebar-react'
import styled from 'styled-components'

const TopContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  .info {
    font-weight: 400;
    color: var(--bg-darker);
    background: #ebebeb;
    font-size: 13px;
    line-height: 24px;
    padding: 12px 20px;
    margin-bottom: 0;
  }
`

const SearchContainer = styled(SimpleBar)`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-bottom: 20px;
  overflow: auto;

  .infinite-scroll-component {
    overflow: hidden !important;
  }

  .loading {
    margin-top: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    > div {
      margin: 0 auto;
      position: relative;
      width: 15px;
      height: 15px;
      border-width: 2px;
    }
  }

  .search-group {
    padding: 8px 20px 0 20px;

    h3 {
      color: #383838;
      font-size: 17px;
      font-weight: 500;
      line-height: 24px;
      margin: 0 0 6px 0;
    }

    &:last-child {
      margin-bottom: 40px;
    }
    &:first-child {
      h3 {
        margin-top: 8px;
      }
    }
  }
`

export { SearchContainer, TopContainer }
