import React, {
    PureComponent,
    RefObject,
    createRef,
    KeyboardEvent,
    ChangeEvent,
} from 'react';
import { sortBy, debounce } from 'lodash';
import { TagsProps, TagsState, Tag } from './Tags.types';
import Icon from './../Icons/Icon';
import { components as defaultComponents } from './Tags.styled';
export { components } from './Tags.styled';

export default class Tags extends PureComponent<TagsProps, TagsState> {
    private resultList: RefObject<HTMLInputElement>;

    constructor(props: TagsProps) {
        super(props);

        this.resultList = createRef();

        this.state = {
            searchResult: [],
            input: '',
            tags: [],
        };
    }

    componentDidMount() {
        this.setState({
            tags: this.props.tags ? this.props.tags : [],
        });
    }

    render() {
        const {
            clearButtonLabel,
            id,
            displayedLabel,
            clearButtonEnabled,
            placeholder,
            components,
        } = this.props;
        const {
            TagSelector,
            SearchContainer,
            SearchDropdown,
            SearchInput,
            SearchResult,
            SearchResultItem,
            RightBarContainer,
            ClearButton,
            TagList,
            SelectedTag,
            TagName,
            CloseIco,
        } = Object.assign({}, defaultComponents, components);
        const { input, searchResult, tags } = this.state;

        return (
            <TagSelector id={id} data-testid="tags">
                <SearchContainer
                    className="search-container"
                    data-testid="search-container"
                >
                    <SearchDropdown
                        className="search-dropdown"
                        data-testid="search-dropdown"
                    >
                        <SearchInput
                            className="search-input"
                            placeholder={placeholder}
                            onChange={this.handleSearch}
                            value={input}
                            onClick={this.handleOnClick}
                            onKeyDown={this.handleKeyPressed}
                            data-testid="search-input"
                        />
                        {searchResult && searchResult.length > 0 && (
                            <SearchResult
                                className="search-result"
                                ref={this.resultList}
                                data-testid="search-result"
                            >
                                {sortBy(searchResult, [
                                    (o: Tag) =>
                                        o[this.props.displayedLabel].replace(
                                            /"/g,
                                            ''
                                        ),
                                ]).map((item: Tag) => (
                                    <SearchResultItem
                                        key={item.id}
                                        className="search-result--item"
                                        onClick={this.handleAddTag(item)}
                                        title={item[this.props.displayedLabel]}
                                        data-testid="search-result-item"
                                    >
                                        {item[this.props.displayedLabel]}
                                    </SearchResultItem>
                                ))}
                            </SearchResult>
                        )}
                    </SearchDropdown>
                    <RightBarContainer
                        className="right-bar-container"
                        data-testid="right-bar-container"
                    >
                        {clearButtonEnabled && (
                            <ClearButton
                                className="btn clear-button"
                                buttonType="link"
                                disabled={tags.length === 0}
                                onClick={this.handleClearAll}
                                data-testid="clear-button"
                            >
                                {clearButtonLabel || 'Clear All'}
                            </ClearButton>
                        )}
                    </RightBarContainer>
                </SearchContainer>
                <TagList className="tag-list" data-testid="tag-list">
                    {tags &&
                        tags.map((item, i) => (
                            <SelectedTag
                                className="selected-tag"
                                key={i}
                                data-testid="selected-tag"
                            >
                                <TagName
                                    className="tag-name"
                                    data-testid="tag-name"
                                >
                                    {item[displayedLabel]}
                                </TagName>
                                <CloseIco
                                    className="close-ico"
                                    onClick={this.removeItemHandler(item.id)}
                                    data-testid="close-ico"
                                >
                                    <Icon icon="close" size="16" />
                                </CloseIco>
                            </SelectedTag>
                        ))}
                </TagList>
            </TagSelector>
        );
    }

    handleKeyPressed = (event: KeyboardEvent): void => {
        const selectedItem = this?.resultList?.current?.getElementsByClassName(
            'search-result--item-selected'
        )[0] as HTMLElement;
        let newSelectedItem = selectedItem;

        if (selectedItem) {
            if (event.key === 'ArrowUp') {
                if (selectedItem.previousElementSibling) {
                    selectedItem.classList.remove(
                        'search-result--item-selected'
                    );
                    selectedItem.previousElementSibling.classList.add(
                        'search-result--item-selected'
                    );
                    newSelectedItem = selectedItem.previousElementSibling as HTMLElement;
                } else {
                    selectedItem.classList.remove(
                        'search-result--item-selected'
                    );
                    const lastChild = this?.resultList?.current
                        ?.lastChild as HTMLElement;
                    lastChild.classList.add('search-result--item-selected');

                    newSelectedItem = this?.resultList?.current
                        ?.lastChild as HTMLElement;
                }
            } else if (event.key === 'ArrowDown') {
                if (selectedItem.nextElementSibling) {
                    selectedItem.classList.remove(
                        'search-result--item-selected'
                    );
                    selectedItem.nextElementSibling.classList.add(
                        'search-result--item-selected'
                    );
                    newSelectedItem = selectedItem.nextElementSibling as HTMLElement;
                } else {
                    selectedItem.classList.remove(
                        'search-result--item-selected'
                    );
                    const firstChild = this?.resultList?.current
                        ?.firstChild as HTMLElement;

                    firstChild.classList.add('search-result--item-selected');
                    newSelectedItem = this?.resultList?.current
                        ?.firstChild as HTMLElement;
                }
            }

            if (event.key === 'Enter' && newSelectedItem) {
                selectedItem.click();
            }
        }
    };

    handleOnClick = () => {
        const { suggestions, dropdownMode } = this.props;
        const { tags } = this.state;

        if (dropdownMode) {
            const searchResult = suggestions.filter(
                (item: Tag) => !tags.map((i: Tag) => i.id).includes(item.id)
            );

            this.setState(
                {
                    searchResult,
                },
                () => {
                    if (this?.resultList?.current?.firstChild) {
                        (this.resultList.current
                            .firstChild as HTMLElement).classList.add(
                            'search-result--item-selected'
                        );
                    }
                }
            );
        }
    };

    handleAddTag = (newItem: Tag) => {
        return () => {
            const tags = [newItem, ...this.state.tags];

            this.handleTagListChange(tags);
            this.setState({
                searchResult: [],
                input: '',
                tags,
            });
        };
    };

    searchInSuggestions = debounce((value: string) => {
        let searchResult: Tag[] = [];
        const { searchBy } = this.props;

        if (value) {
            if (searchBy) {
                searchResult = this.props.suggestions.filter((item: Tag) => {
                    return searchBy(item).find((searchableField: Tag) =>
                        searchableField.toLowerCase().includes(value)
                    );
                });
            } else {
                searchResult = this.props.suggestions.filter((item: Tag) =>
                    item[this.props.displayedLabel]
                        .toLowerCase()
                        .includes(value)
                );
            }

            if (!this.props.multipleSelect) {
                searchResult = searchResult.filter(
                    (item: Tag) =>
                        !this.state.tags.map((i: Tag) => i.id).includes(item.id)
                );
            }
        }

        this.setState({ searchResult }, () => {
            if (this?.resultList?.current?.firstChild) {
                (this.resultList.current
                    .firstChild as HTMLElement).classList.add(
                    'search-result--item-selected'
                );
            }
        });
    }, 150);

    handleSearch = (event: ChangeEvent) => {
        this.setState({ input: (event.target as HTMLInputElement).value });
        if (this.props.suggestions && this.props.suggestions.length) {
            this.searchInSuggestions(
                (event.target as HTMLInputElement).value.toLowerCase()
            );
        }
    };

    handleClearAll = () => {
        this.handleTagListChange([]);
    };

    removeItemHandler = (id: string) => {
        return () => {
            this.handleTagListChange(
                this.state.tags.filter((item: Tag) => item.id !== id)
            );
        };
    };

    handleTagListChange(newValue: Tag[]) {
        if (this?.props?.onChange) {
            this.props.onChange(newValue);
        }
        this.setState({
            tags: newValue,
        });
    }
}
