import styled, { StyledComponent, keyframes } from 'styled-components';
import {
    TableStyledProps,
    TableComponents,
    HeaderCellProps,
} from './Table.types';

export const TableStyled = styled.div<TableStyledProps>`
    display: table;
    position: relative;
    width: 100%;
    background-color: #fff;
    table-layout: fixed;
    overflow: auto;
    min-width: ${(props: TableStyledProps) => props.minWidth};
    min-height: ${(props: TableStyledProps) => props.minHeight};
`;

export const THead = styled.div`
    display: table-header-group;
    position: relative;
    width: 100%;
    background-color: #fff;
    background-color: #292e33;
`;

export const HeaderRow = styled.div`
    display: table-row;
    position: relative;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.72;
    letter-spacing: normal;
    color: #ffffff;
`;

export const HeaderCell = styled.div<HeaderCellProps>`
    position: ${({ fixedHeader }) => (fixedHeader ? 'sticky' : 'relative')};
    width: ${({ width }) => (width ? width : 'auto')};
    top: 0;
    padding: 12px 15px 12px 22px;
    display: table-cell;
    border: none;
    cursor: default;
    background-color: #292e33;

    .clickable {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
    }
`;

export const SortIcons = styled.span`
    display: inline-flex;
    flex-direction: column;
    font-size: 10px;
    margin-left: 10px;
    background: none;
    .caret-up {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid black;
    }

    .caret-down {
        margin-top: 3px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid black;
    }

    .active-sort {
        border-top-color: #ffffff;
        border-bottom-color: #ffffff;
    }
`;

export const TBody = styled.div`
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
`;
export const Cell = styled.div`
    display: table-cell;
    color: #525252;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    border: none;
    padding: 16px 24px;
    vertical-align: middle;
    cursor: default;
`;

export const Row = styled.div`
    display: table-row;
    table-layout: fixed;
    width: 100%;
    height: 56px;
    color: #525252;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    border: none;
    padding: 10px 24px;
    vertical-align: middle;
    cursor: default;

    &:nth-child(even) {
        background: #fff;
    }
    &:nth-child(odd) {
        background: #fafafa;
    }

    :hover {
        background: #ebebeb;
    }

    &.table-row-selected {
        background: #0e8fc9;

        ${Cell} {
            color: #fff;
        }
    }
`;

const tableLoader = keyframes`
  {
    0% {
      background-position: 100% 0;
    }
    100% {
      background-position: -100% 0;
    }
  }
`;

export const LoadingAnimation = styled.div`
    height: 8px;
    background: linear-gradient(
        to right,
        #ebebeb 20%,
        #d9d9d9 50%,
        #ebebeb 80%
    );
    background-size: 200%;
    animation: ${tableLoader} 1.5s infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    border-radius: 3px;
    margin-top: 7px;
    width: 100%;
`;

export const components: TableComponents = {
    THead,
    HeaderRow,
    HeaderCell,
    SortIcons,
    TBody,
    Row,
    Cell,
    TableStyled,
    LoadingAnimation,
};
