import styled from 'styled-components';
import Input from './../Input/Input';
import Button from './../Button/Button';
import { TagsComponents } from './Tags.types';

export const TagSelector = styled.div`
    width: 100%;
    position: relative;
`;
export const SearchContainer = styled.div`
    display: flex;
`;

export const SearchDropdown = styled.div`
    flex: 1;
    display: flex;
    position: relative;
`;

export const SearchInput = styled(Input)`
    flex: 1;

    :focus {
        outline: none;
    }

    :placeholder {
        color: #acadad;
    }
`;

export const SearchResult = styled.div`
    position: absolute;
    z-index: 1;
    border-left: 1px solid rgba(235, 235, 235, 0.7);
    height: auto;
    box-shadow: 0 4px 4px 0 rgba(45, 46, 46, 0.16),
        0 0 4px 0 rgba(45, 46, 46, 0.2);
    background-color: #ffffff;
    border-radius: 4px;
    top: 42px;
    width: 100%;
`;

export const SearchResultItem = styled.div`
    padding-left: 18px;
    height: 40px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.86;
    letter-spacing: normal;
    color: #383838;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.search-result--item-selected {
        background-color: rgba(235, 235, 235, 0.51);
    }

    :hover {
        background-color: #ebebeb;
    }
`;

export const RightBarContainer = styled.div`
    flex: 1;
    align-items: stretch;
`;

export const ClearButton = styled(Button)`
    display: flex;

    :hover {
        background: none;
    }

    :focus {
        box-shadow: none;
    }

    :disabled {
        cursor: not-allowed;
    }
`;

export const TagList = styled.div`
    padding: 12px 0;
`;

export const SelectedTag = styled.div`
    margin: 0 12px 5px 0;
    position: relative;
    font-family: Roboto;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.85;
    letter-spacing: normal;
    color: #525252;
    display: inline-block;

    :after {
        content: '';
        height: 40%;
        width: 1px;
        position: absolute;
        top: 10px;
        right: 0;
        background-color: #e3e3e3;
`;

export const TagName = styled.div`
    display: inline-block;
`;

export const CloseIco = styled.div`
    padding: 8px 8px 0px 8px;
    display: inline-block;
    vertical-align: sub;
    cursor: pointer;

    hover {
        g use {
            fill: #00779e;
        }
    }
`;

export const components: TagsComponents = {
    TagSelector,
    SearchContainer,
    SearchDropdown,
    SearchInput,
    SearchResult,
    SearchResultItem,
    RightBarContainer,
    ClearButton,
    TagList,
    SelectedTag,
    TagName,
    CloseIco,
};

export default TagSelector;
