import styled from 'styled-components';
import close from './svg/close.svg';
import error from './svg/error.svg';
import magnifyingGlass from './svg/magnifying-glass.svg';
import newFeatures from './svg/new-features.svg';
import verticalEllipsis from './svg/vertical-ellipsis.svg';
import warning from './svg/warning.svg';
import briefcase from './svg/briefcase.svg';
import glossary from './svg/glossary.svg';
import questionDialog from './svg/question-dialog.svg';
import scale from './svg/scale.svg';
import barChartMonitor from './svg/bar-chart-monitor.svg';
import download from './svg/download.svg';
import assessment from './svg/assessment.svg';
import library from './svg/library.svg';
import percentage from './svg/percentage.svg';

import admin from './svg/admin.svg';
import autoprovisioning from './svg/autoprovisioning.svg';
import avatar from './svg/avatar.svg';
import barChart from './svg/bar-chart.svg';
import boxArrowUp from './svg/box-arrow-up.svg';
import brokenLink from './svg/broken-link.svg';
import buildings from './svg/buildings.svg';
import checkmark from './svg/checkmark.svg';
import chevronDown from './svg/chevron-down.svg';
import chevronRight from './svg/chevron-right.svg';
import federation from './svg/federation.svg';
import gear from './svg/gear.svg';
import info from './svg/info.svg';
import lineChart from './svg/line-chart.svg';
import paperKey from './svg/paper-key.svg';
import plus from './svg/plus.svg';
import thumbDown from './svg/thumb-down.svg';
import thumbUp from './svg/thumb-up.svg';
import upload from './svg/upload.svg';

const Icons = {
    close,
    error,
    magnifyingGlass,
    newFeatures,
    verticalEllipsis,
    warning,
    briefcase,
    glossary,
    questionDialog,
    scale,
    barChartMonitor,
    download,
    assessment,
    library,
    percentage,
    admin,
    autoprovisioning,
    avatar,
    barChart,
    boxArrowUp,
    brokenLink,
    buildings,
    checkmark,
    chevronDown,
    chevronRight,
    federation,
    gear,
    info,
    lineChart,
    paperKey,
    plus,
    thumbDown,
    thumbUp,
    upload,
};

Object.keys(Icons).map(key => {
    Icons[key] = styled(Icons[key])`
        .${key
                .replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2')
                .toLowerCase()}_svg__ico {
            fill: ${props => props.color || '#8B8C8C'};
        }
    `;
});

export default Icons;
