import React, { useCallback, useMemo, useState } from 'react'
import classnames from 'classnames'
import useFeedback, {
  DEFAULT_FEEDBACK_STATS
} from '../../hooks/api/useFeedback'
import { ReactComponent as ThumbsDown } from '../../assets/thumbs-down.svg'
import { ReactComponent as ThumbsUp } from '../../assets/thumbs-up.svg'
import { useCMSState } from '../../hooks'
import { IArticleStats } from '../../domain'
import { FeedbackContainer } from './Feedback.style'
import FeedbackModal from './Feedback.modal'

const Feedback = () => {
  const [feedbackModalOpen, setFeedbackModalOpen] = useState<boolean>(false)
  const [feedbackModalLoading, setFeedbackModalLoading] = useState<boolean>(
    false
  )
  const [stats, setStats] = useState<IArticleStats>(DEFAULT_FEEDBACK_STATS)

  const { widget } = useCMSState()
  const { articleOptions } = widget

  const { postFeedback } = useFeedback({
    articleId: articleOptions && articleOptions.id,
    onSuccess: (data) => {
      setStats(data.data.body)
    }
  })

  const onCancelNegative = useCallback(() => {
    setFeedbackModalOpen(false)
  }, [])

  const submitNegative = useCallback(
    async (data) => {
      if (articleOptions && articleOptions.id) {
        setFeedbackModalLoading(true)
        const result = stats.helpful === 0 ? null : 0
        setStats({
          ...stats,
          helpful: result,
          totalHelpful:
            stats.helpful === 1 ? stats.totalHelpful - 1 : stats.totalHelpful,
          totalNotHelpful:
            stats.helpful === 0
              ? stats.totalNotHelpful - 1
              : stats.totalNotHelpful + 1
        })
        await postFeedback({
          ...data,
          helpful: result,
          article: articleOptions.id
        })
        setFeedbackModalOpen(false)
        setFeedbackModalLoading(false)
      }
    },
    [stats, articleOptions, postFeedback]
  )

  const thumbsUp = useCallback(async () => {
    if (articleOptions && articleOptions.id) {
      const result = stats.helpful === 1 ? null : 1
      setStats({
        ...stats,
        helpful: result,
        totalHelpful:
          stats.helpful === 1 ? stats.totalHelpful - 1 : stats.totalHelpful + 1,
        totalNotHelpful:
          stats.helpful === 0
            ? stats.totalNotHelpful - 1
            : stats.totalNotHelpful
      })
      await postFeedback({
        helpful: result,
        article: articleOptions.id
      })
    }
  }, [stats, articleOptions, postFeedback])

  const thumbsDown = useCallback(() => {
    if (stats.helpful !== 0) {
      setFeedbackModalOpen(true)
    } else {
      submitNegative({})
    }
  }, [stats.helpful, submitNegative])

  const hasStats = useMemo(() => stats.totalHelpful || stats.totalNotHelpful, [
    stats
  ])

  return (
    <React.Fragment>
      <FeedbackContainer
        data-test-id='article-feedback'
        helpful={stats.helpful}
        shown={Boolean(articleOptions && articleOptions.id)}
      >
        <ThumbsUp data-test-id='feedback-thumbsup' onClick={thumbsUp} />
        <div className='number positive'>{stats.totalHelpful}</div>
        <div
          className={classnames('line', {
            positive: Boolean(stats.totalHelpful)
          })}
          style={{ flex: stats.totalHelpful || (hasStats ? 0 : 1) }}
        />
        <div
          className={classnames('line', {
            negative: Boolean(stats.totalNotHelpful)
          })}
          style={{ flex: stats.totalNotHelpful || (hasStats ? 0 : 1) }}
        />
        <div className='number negative'>{stats.totalNotHelpful}</div>
        <ThumbsDown data-test-id='feedback-thumbsdown' onClick={thumbsDown} />
      </FeedbackContainer>
      {feedbackModalOpen && (
        <FeedbackModal
          loading={feedbackModalLoading}
          onSubmit={submitNegative}
          onCancel={onCancelNegative}
        />
      )}
    </React.Fragment>
  )
}

export default Feedback
