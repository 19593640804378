import React, { useCallback, useMemo } from 'react'
import { ISearchResult } from '../../../domain'
import { processContent } from './process.content'
import { SearchResultStyled } from './SearchResult.style'
import { useCMSDispatch, useCMSState } from '../../../hooks'
import { WIDGET_OPEN_ARTICLE } from '../../../context/cms.actions'
import { getArticleHistoryObject } from '../../../utils'

const SearchResult: React.FC<ISearchResult & { type: 'widget' | 'full' }> = ({
  id,
  catId,
  parentId,
  parentTitle,
  parentSlug,
  slug,
  catSlug,
  catTitle,
  title,
  content,
  type
}) => {
  const { core } = useCMSState()
  const dispatch = useCMSDispatch()
  const { history } = core

  const goToArticle = useCallback(() => {
    const options = {
      id,
      articleSlug: slug,
      catId: catId,
      categorySlug: catSlug,
      rootSlug: parentSlug,
      rootId: parentId || catId
    }
    const articleHistoryObject = getArticleHistoryObject(options)

    if (type === 'widget') {
      dispatch({
        type: WIDGET_OPEN_ARTICLE,
        value: options
      })
    } else if (articleHistoryObject && history) {
      history.push(articleHistoryObject)
    }
  }, [id, type, history, parentId, parentSlug, catSlug, catId, slug, dispatch])

  const shortenedContent = useMemo(
    () => content && processContent(id, content),
    [id, content]
  )

  return (
    <SearchResultStyled
      className='cms-search-result'
      onClick={goToArticle}
      data-test-id='cms-search-list-item'
    >
      <div className='cms-search-result__header'>
        {parentTitle ? parentTitle + ' > ' : ''}
        {catTitle}
      </div>
      {shortenedContent || (
        <div className='cms-search-result__title'>{title}</div>
      )}
    </SearchResultStyled>
  )
}

export default SearchResult
