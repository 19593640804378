// Notification actions
export const NOTIFICATIONS_MARK_SEEN = 'notifications:mark_seen'
export const NOTIFICATIONS_MARK_ALL_SEEN = 'notifications:mark_all_seen'
export const NOTIFICATIONS_LOAD = 'notifications:load'

// Widget actions
export const WIDGET_HOME = 'widget:home'
export const WIDGET_SEARCH = 'widget:search'
export const WIDGET_MINIMIZE = 'widget:minimize'
export const WIDGET_CLOSE = 'widget:close'
export const WIDGET_OPEN = 'widget:open'
export const WIDGET_LOAD_RESULTS = 'widget:load_results'
export const WIDGET_SEARCH_CHANGE = 'widget:search_change'
export const WIDGET_OPEN_ARTICLE = 'widget:open_article'
export const WIDGET_OPEN_MENU = 'widget:open_menu'
export const WIDGET_CLOSE_MENU = 'widget:close_menu'
export const WIDGET_SELECT_MENU_ITEM = 'widget:select_menu_item'

export type WidgetAction<T> =
  | { type: typeof WIDGET_SEARCH; value?: T }
  | { type: typeof WIDGET_HOME; value?: T }
  | { type: typeof WIDGET_OPEN; value?: T }
  | { type: typeof WIDGET_MINIMIZE; value?: T }
  | { type: typeof WIDGET_CLOSE; value?: T }
  | { type: typeof WIDGET_LOAD_RESULTS; value?: T }
  | { type: typeof WIDGET_SEARCH_CHANGE; value?: T }
  | { type: typeof WIDGET_OPEN_ARTICLE; value?: T }
  | { type: typeof WIDGET_OPEN_MENU; value?: T }
  | { type: typeof WIDGET_CLOSE_MENU; value?: T }
  | { type: typeof WIDGET_SELECT_MENU_ITEM; value?: T }

export type NotificationAction<T> =
  | { type: typeof NOTIFICATIONS_MARK_SEEN; value?: T }
  | { type: typeof NOTIFICATIONS_MARK_ALL_SEEN; value?: T }
  | { type: typeof NOTIFICATIONS_LOAD; value?: T }

export type Action<T> =
  | NotificationAction<T>
  | WidgetAction<T>
  | { type: any; value?: T }
