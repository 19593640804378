import styled from 'styled-components';
import {
    OverlayContentWrapperProps,
    OverlayOverlapProps,
} from './Overlay.types';

export const OverlayContainer = styled.div`
    position: relative;
    width: 100%;
    flex-grow: 1;
    height: 100%;
`;

export const OverlayOverlap = styled.div<OverlayOverlapProps>`
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${(props: OverlayOverlapProps) =>
        props.overlapColor || '#fff'};
    opacity: ${(props: OverlayOverlapProps) => props.opacity || 0.7};
`;

export const OverlayContentWrapper = styled.div<OverlayContentWrapperProps>`
    position: absolute;
    top: ${(props: OverlayContentWrapperProps) => props.offsetFormTop || 50}%;
    left: 50%;
    transform: translate(-50%, 0);
`;
