import styled, { keyframes } from 'styled-components';
import { SpinnerProps } from './Spinner.types';

const getSize = (size: string = 'large', width?: string, height?: string) => {
    if (width && height) {
        return `
            width: ${width};
            height: ${height};
        `;
    }

    return types[size];
};

const types = {
    small: `
        width: 1rem;
        height: 1rem;
      `,
    medium: `
        width: 2rem;
        height: 2rem;
      `,
    large: `
        width: 3rem;
        height: 3rem;
    `,
};

const rotate = (rotationDirection: string = 'right') => keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(${rotationDirection === 'left' ? '-' : ''}360deg);
  }
`;

export const SpinnerContainer = styled.div<SpinnerProps>`
    ${(props: SpinnerProps) => getSize(props.size, props.width, props.height)}
    color: ${(props: SpinnerProps) =>
        `${props.color ? props.color : '#007bff'};`}
    display: inline-block;
    vertical-align: text-bottom;
    border: ${(props: SpinnerProps) =>
        `${props.borderRadius || '0.25em'} solid currentColor;`}
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: ${(props: SpinnerProps) =>
        rotate(props.rotationDirection)} ${(props: SpinnerProps) =>
    `${props.rotateSpeed ? props.rotateSpeed + 's' : '.75s'} linear infinite;`}
    animation:  ${(props: SpinnerProps) => rotate(props.rotationDirection)} ${(
    props: SpinnerProps
) => `${props.rotateSpeed ? props.rotateSpeed + 's' : '.75s'} linear infinite;`}
`;

export const SpinnerBody = styled.span`
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
`;
