import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

/*
  This is explicitly for projects which use the react-onclickoutside
  package as IE does not support classList for SVG elements
*/
if (!('classList' in SVGElement.prototype)) {
  Object.defineProperty(SVGElement.prototype, 'classList', {
    get() {
      return {
        contains: (className: any) => {
          return this.className.baseVal.split(' ').indexOf(className) !== -1;
        }
      };
    }
  });
}

import {
  useCMSApi,
  useCMSDispatch,
  useCMSState,
  useCMSEventTransfer,
  useSearch
} from './hooks'
import CMSProvider from './context/CMSProvider'
import Notification from './components/Notification/Notification'
import Input from './components/Input/Input'
import Notifications from './components/Notifications/Notifications'
import DocsButton from './components/DocsButton/DocsButton'
import SearchList from './components/SearchList/SearchList'
import Widget from './components/Widget/Widget'
import * as Domain from './domain'
import './loadmathjax.js'
import './styles.css'

export {
  Domain,
  DocsButton,
  SearchList,
  Notification,
  Notifications,
  Input,
  CMSProvider,
  useCMSApi,
  useCMSDispatch,
  useCMSState,
  useSearch,
  useCMSEventTransfer,
  Widget
}
