import styled from 'styled-components'

const FooterContainer = styled.div<{ hasFeedback?: boolean }>`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  max-width: 100%;
  width: 100%;
  overflow: hidden;

  ${({ hasFeedback }) =>
    hasFeedback &&
    `
    height: 107px;
  `}
`

const FooterNav = styled.div<{ article?: boolean }>`
  position: relative;
  background-color: #292e33;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  .separator {
    margin-left: 32px;
    margin-right: 32px;
    width: 1px;
    height: 63px;
    background-color: #000000;
  }
`

const FooterLink = styled.div`
  font-family: Roboto, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 600;
  font-size: 13px;

  svg {
    margin-right: 12px;
    g > path:last-child {
      fill: #cecece;
    }
  }

  &:hover {
    cursor: pointer;

    svg {
      g > path:last-child {
        transition: fill 0.3s ease-in-out;
        fill: white;
      }
    }
  }
`

const FooterText = styled.div`
  width: 100%;
  padding: 12px 0;
  font-size: 12px;
  flex-shrink: 0;
  text-align: center;
  color: var(--bg-darker);
`

export { FooterContainer, FooterLink, FooterNav, FooterText }
