import * as React from 'react'
import { AxiosInstance } from 'axios'

export const CMSApiContext = React.createContext<AxiosInstance | undefined>(
  undefined
)

function useCMSApi() {
  const context = React.useContext(CMSApiContext)
  if (context === undefined) {
    throw new Error('useCMSApi must be used within a CMSProvider')
  }
  return context
}

export default useCMSApi
