import React, { DetailedHTMLProps } from 'react'
import styled from 'styled-components'
import { ReactComponent as Clear } from '../../assets/close.svg'
import { ReactComponent as Search } from '../../assets/magnifying-glass-24.svg'
import { Spinner } from 'c2-uikit'

const InputContainer = styled.div<{ bordered?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  background: white;
  min-width: 24vw;

  ${(props) =>
    props.bordered &&
    `
    border-radius: 2px;
    border: 1px solid #dee0e0;
  `}

  .clear-icon {
    margin-right: 12px;
    margin-left: 12px;
  }
  .spinner {
    width: 12px;
    height: 12px;
    border-width: 2px;
    margin: 0 12px;
  }
`

const StyledAddon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  > * {
    margin: 0 8px;
  }
`

const StyledInput = styled.input`
  background: white;
  padding: 4px 8px 4px 0;
  outline: none;
  font-size: 14px;
  flex: 2;
  color: gray;
  border: none;

  ::-ms-clear {
    display: none;
  }

  &:disabled + .clear-icon {
    cursor: not-allowed;
  }
  &:not(:disabled) + .clear-icon {
    cursor: pointer;
    &:hover {
      opacity: 0.7;
      transition: opacity 0.2s ease-in-out;
    }
  }
`

type InputProps = DetailedHTMLProps<any, any> & {
  inputHeight?: number
  containerClassname?: string
  addon?: React.ReactNode | 'search'
  onClear?: () => void
  loading?: boolean
  bordered?: boolean
  testId?: string
}

const Input: React.FC<InputProps> = React.forwardRef(
  (
    {
      addon,
      inputHeight,
      onClear,
      containerClassname,
      loading,
      bordered,
      testId,
      ...props
    },
    ref
  ) => {
    const height = inputHeight || 38
    return (
      <InputContainer
        data-test-id={testId}
        bordered={bordered}
        className={containerClassname || ''}
        style={{ height }}
      >
        {addon && (
          <StyledAddon>
            {loading ? (
              <Spinner color='#0E8FC9' size='small' borderRadius='2px' />
            ) : addon === 'search' ? (
              <Search />
            ) : (
              addon
            )}
          </StyledAddon>
        )}
        <StyledInput ref={ref} {...props} />
        {onClear && <Clear className='clear-icon' onClick={onClear} />}
      </InputContainer>
    )
  }
)

export default Input
