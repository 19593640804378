import React, { FC } from 'react';
import { SpinnerContainer, SpinnerBody } from './Spinner.styled';
import { SpinnerProps } from './Spinner.types';

const Spinner: FC<SpinnerProps> = ({
    size,
    color,
    width,
    height,
    rotateSpeed,
    borderRadius,
    rotationDirection,
}) => {
    return (
        <SpinnerContainer
            data-testid="spinner"
            size={size}
            color={color}
            width={width}
            height={height}
            rotateSpeed={rotateSpeed}
            borderRadius={borderRadius}
            rotationDirection={rotationDirection}
        >
            <SpinnerBody>Loading...</SpinnerBody>
        </SpinnerContainer>
    );
};

export default Spinner;
