import { useMemo } from 'react'
import { AxiosResponse } from 'axios'
import useSWR from 'swr'
import useCMSApi from '../useCMSApi'
import useCMSState from '../useCMSState'
import { NotificationModel, NotificationsResponse } from '../../domain'
import { NotificationState } from '../../context/reducers/notification.reducer'

const localCacheStr = localStorage.getItem('seenNotifications') || '{}'
const seenNotifications = JSON.parse(localCacheStr) || {}
const NOTIFICATION_DEFAULT_STATE: NotificationState = {
  notifications: [],
  notificationsArticles: [],
  notificationsCategories: [],
  seenNotifications
}

const DEFAULT_NOTIFICATION_RESPONSE = {
  id: 0,
  data: null,
  notifications: [],
  newArticles: [],
  newCategories: []
}

const useNotifications = (params: { includeNew?: boolean }) => {
  const { includeNew } = params
  const { core } = useCMSState()
  const api = useCMSApi()
  const { data, error, revalidate } = useSWR<
    AxiosResponse<{ body: NotificationsResponse }>
  >(
    `/cms/notifications/latest?app=${core.app}${includeNew ? '&new' : ''}`,
    api,
    { revalidateOnFocus: false }
  )
  const transformedData = useMemo(() => {
    if (!data) {
      return NOTIFICATION_DEFAULT_STATE
    }
    const username = sessionStorage.getItem('username') || '_'
    const seenCache = JSON.parse(
      localStorage.getItem('seenNotifications') || '{}'
    )

    const {
      id,
      data: notificationsData,
      notifications,
      newArticles,
      newCategories
    } = data.data
      ? data.data.body || DEFAULT_NOTIFICATION_RESPONSE
      : DEFAULT_NOTIFICATION_RESPONSE

    return {
      notificationsId: id,
      notificationsData,
      notificationsArticles: newArticles,
      notificationsCategories: newCategories,
      notifications: (notifications || []).filter(
        (a: NotificationModel) => (seenCache[username] || []).indexOf(a.id) < 0
      )
    }
  }, [data])

  return {
    revalidate,
    data: transformedData,
    error
  }
}

export default useNotifications
