import styled from 'styled-components'

const SearchResultStyled = styled.div`
  background-color: white;
  border-radius: 4px;
  padding: 12px 16px 12px 16px;
  margin: 14px 0 8px 0;

  &:hover {
    cursor: pointer;
    opacity: 0.8;

    transition: all 0.3s ease-in-out;

    .cms-search-result__title {
      font-weight: 500;
    }
  }

  .cms-search-result__header {
    color: #6a6a6a;
    font-size: 13px;
    font-family: Roboto, sans-serif;
    font-weight: 400;
  }

  .cms-search-result__title {
    font-family: Roboto, sans-serif;
    color: #0b74a3;
    font-size: 16px;
    font-weight: normal;
    margin: 12px 0 6px 0;
    max-width: 60%;
  }

  p {
    font-size: 14px;
    padding-right: 24px;
    line-height: 1.7;
    color: #383838;
  }

  &:last-child {
    margin-bottom: 20px;
  }
`

export { SearchResultStyled }
