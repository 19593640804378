import { useCallback, useState } from 'react'
import qs from 'query-string'

export const getQueryStringValue = (
  key: string,
  queryString = window.location.search
) => {
  const values = qs.parse(queryString)
  return values[key]
}

export const setQueryStringValue = (
  key: string,
  value?: string,
  queryString = window.location.search
) => {
  const values = qs.parse(queryString)
  if (!value) {
    delete values[key]
  } else {
    values[key] = value
  }
  return qs.stringify(values)
}

export const getModifiedSearch = (params: {
  [key: string]: string | undefined
}) => {
  const values = qs.parse(window.location.search)
  Object.keys(params).forEach((p) => {
    if (!params[p]) {
      delete values[p]
    } else {
      values[p] = String(params[p])
    }
  })
  return qs.stringify(values)
}

function useQueryString(key: string, initialValue?: string) {
  const [value, setValue] = useState(getQueryStringValue(key) || initialValue)
  const onSetValue = useCallback(
    (newValue) => {
      setValue(newValue)
      setQueryStringValue(key, newValue)
    },
    [key]
  )

  return [value, onSetValue]
}

export default useQueryString
