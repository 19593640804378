import React, { useCallback, useEffect } from 'react';

import Notification from '../Notification/Notification';

import { useCMSDispatch, useCMSState } from '../../hooks';
import useNotifications from '../../hooks/api/useNotifications';

import { NOTIFICATIONS_LOAD } from '../../context/cms.actions';

import styles from './Notifications.style.css';

export type NotificationsProps = { includeNew?: boolean };

const Notifications: React.FC<NotificationsProps> = ({ includeNew }) => {
  const dispatch = useCMSDispatch();
  const { core } = useCMSState();
  const { app, sendToCmsIFrame } = core;
  const { data, revalidate } = useNotifications({ includeNew });
  const { notifications, notificationsId } = data;

  const handleNotificationClick = useCallback(
    (action, label) => {
      if (sendToCmsIFrame) {
        sendToCmsIFrame({
          type: 'track',
          category: 'Notifications',
          action: `${app}_${action}`,
          label,
          value: notificationsId
        });
      }
    },
    [sendToCmsIFrame, app, notificationsId]
  );

  useEffect(() => {
    dispatch({type: NOTIFICATIONS_LOAD, value: {
      id: data.notificationsId,
      data: data.notificationsData,
      notifications: data.notifications,
      newArticles: data.notificationsArticles,
      newCategories: data.notificationsCategories
    }})
  }, [data]);

  return (
    <div
      className={`cybercube-notifications ${styles.notifications}`}
      data-test-id='cybercube-notifications'
    >
      {notifications.length ? (
        notifications.map((notification) => (
          <Notification
            className='cybercube-notification'
            key={notification.id}
            revalidate={revalidate}
            notification={notification}
            onClick={handleNotificationClick}
          />
        ))
      ) : (
        <span />
      )}
    </div>
  )
};

export default Notifications;
