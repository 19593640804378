import {
  WIDGET_CLOSE,
  WIDGET_HOME,
  WIDGET_MINIMIZE,
  WIDGET_OPEN,
  WIDGET_OPEN_ARTICLE,
  WIDGET_SEARCH,
  WIDGET_SEARCH_CHANGE,
  WidgetAction
} from '../cms.actions'
import { WidgetState } from '../cms.reducer'

const WidgetReducer = (state: WidgetState, action: WidgetAction<any>) => {
  switch (action.type) {
    case WIDGET_OPEN: {
      return {
        ...state,
        state: 'main',
        articleOptions:
          state.state === 'minimize' ? state.articleOptions : null,
        page: state.state === 'minimize' ? state.page : 'search'
      }
    }
    case WIDGET_SEARCH_CHANGE: {
      return {
        ...state,
        search: action.value
      }
    }
    case WIDGET_MINIMIZE: {
      return {
        ...state,
        state: 'minimize'
      }
    }
    case WIDGET_HOME: {
      return {
        ...state,
        page: 'search',
        search: '',
        articleOptions: null
      }
    }
    case WIDGET_SEARCH: {
      return {
        ...state,
        page: 'search',
        articleOptions: null
      }
    }
    case WIDGET_CLOSE: {
      return {
        ...state,
        state: 'hidden',
        page: 'search',
        search: '',
        articleOptions: null
      }
    }
    case WIDGET_OPEN_ARTICLE: {
      return {
        ...state,
        page: 'article',
        articleOptions: action.value
      }
    }
    default: {
      return state
    }
  }
}

export default WidgetReducer
