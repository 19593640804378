// @ts-ignore
import { useState } from 'react'
import useSWR from 'swr'
import useCMSApi from '../useCMSApi'
import { getQueryStringValue } from '../useQueryState'

export const getContentModeParam = (firstparam?: boolean) => {
  const mode = getQueryStringValue('mode', window.location.search)
  const preview = getQueryStringValue('preview', window.location.search)
  const modes = (typeof mode === 'object' ? mode : mode ? [mode] : []) || []
  if (modes.length) {
    const query = modes.reduce(
      (prev: string, curr: string) => prev + '&mode=' + curr,
      ''
    )

    return (
      `${firstparam ? '?' : ''}${query}` +
      (preview ? `&preview=${preview}` : '')
    )
  }
  return firstparam ? '?' : ''
}

export const getDetailedArticlePath = (
  category: string,
  article: string,
  rootId?: number
) =>
  `/cms/categories/${category}/articles/${article}?${
    rootId ? `root=${rootId}` : ''
  }${getContentModeParam()}`

// @ts-ignore
export const useArticle = ({ category, article, rootId }) => {
  const [lastFetched, setLastFetched] = useState<Date>()
  const api = useCMSApi()

  // eslint-disable-next-line no-unused-vars
  const { data, error, revalidate } = useSWR(
    getDetailedArticlePath(category, article, rootId),
    api,
    {
      revalidateOnFocus: false,
      onSuccess: () => {
        setLastFetched(new Date())
      }
    }
  )

  return {
    lastFetched,
    revalidate,
    data,
    error
  }
}

export default useArticle
