import React, { useCallback } from 'react'
import cx from 'classnames'
import { Spinner } from 'c2-uikit'
import styles from './Widget.style.css'
import { useCMSDispatch, useCMSState } from '../../hooks'
import { WIDGET_OPEN } from '../../context/cms.actions'
import { ReactComponent as Library } from '../../assets/library-32.svg'
import WidgetApp from '../WidgetApp/WidgetApp'

const { cmsWidgetButton, cmsWidget, cmsWidgetOpen, widgetMinimized } = styles
const { spring, Motion } = require('react-motion')

const Widget: React.FC = () => {
  const { widget } = useCMSState()
  const dispatch = useCMSDispatch()
  const { state, loading } = widget

  const handleOpen = useCallback(() => {
    dispatch({ type: WIDGET_OPEN })
  }, [dispatch])

  const hiddenState = state === 'minimize' || state === 'hidden'

  return (
    <React.Fragment>
      <div
        className={cx(cmsWidgetButton)}
        onClick={handleOpen}
        data-test-id='widget-app-button-handler'
      >
        {!loading ? <Spinner color='white' size='small' /> : <Library />}
        {state === 'minimize' && (
          <div
            className={widgetMinimized}
            data-test-id='widget-app-minimized-dot'
          />
        )}
      </div>

      <Motion
        defaultStyle={{
          right: hiddenState ? -900 : 0,
          opacity: hiddenState ? 0 : 1
        }}
        style={{
          right: spring(hiddenState ? -900 : 0),
          opacity: spring(hiddenState ? 0 : 1)
        }}
      >
        {(style: any) => (
          <div
            data-test-id='widget-app-container'
            style={style}
            className={cx(cmsWidget, {
              [cmsWidgetOpen]: state === 'main'
            })}
          >
            <WidgetApp />
          </div>
        )}
      </Motion>
    </React.Fragment>
  )
}

export default React.memo(Widget)
