import styled from 'styled-components';

import { InputHTMLAttributes } from 'react';

export const Label = styled.label`
    padding-bottom: 4px;
    font-family: Roboto;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: 0.4px;
    color: #525252;
`;

export const StyledInput = styled.input<InputHTMLAttributes<HTMLInputElement>>`
    padding: 12px 273px 12px 12px;
    border-radius: 2px;
    border: 1px solid #d9d9d9;
    background-color: #ffffff;

    ::placeholder {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #a3a3a3;
    }

    :focus {
        outline: 0;
        border: solid 1px #0e8fc9;
    }

    :disabled {
        background-color: #ebebeb;

        ::placeholder {
            color: #383838;
        }
    }
`;
