import React, { Fragment } from 'react';
import { LoaderProps } from './Loader.types';
import Overlay from './../Overlay/Overlay';
import Spinner from './../Spinner/Spinner';

export default class Loader extends React.PureComponent<LoaderProps> {
    render() {
        return (
            <Overlay
                active={this.props.loading}
                // fixed={this.props.fixed}
                // fullHeight={this.props.fullHeight}
                overlapColor={
                    this.props.overlapColor || 'rgba(255, 255, 255, 0.7)'
                }
                offsetFormTop={this.props.offsetFormTop}
                content={<Spinner />}
            >
                {this.props.children}
            </Overlay>
        );
    }
}
