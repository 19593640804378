import React from 'react'
import HtmlToReact from 'html-to-react'

const Header: React.FC<{ id: string }> = ({ id, children }) => {
  return (
    <div id={id} className='cms-search-result__title'>
      {children}
    </div>
  )
}

const Paragraph: React.FC = ({ children }) => <p>{children}</p>

let ref: any = {}

const extractChildText = (children: any[]): any => {
  if (!children) return ''
  return children
    .map((c) => {
      if (c.type === 'tag') {
        return extractChildText(c.children)
      }
      if (c.type === 'text') {
        return (c.data || '').trim()
      }
      return ''
    })
    .join(' ')
}

const processingInstructions = (id: any) => [
  {
    // This is REQUIRED, it tells the parser
    // that we want to insert our React
    // component as a child
    shouldPreprocessNode: () => false,
    shouldProcessNode: function (node: any) {
      return (
        node.name === 'p' ||
        node.name === 'h1' ||
        node.name === 'div' ||
        node.name === 'a' ||
        node.name === 'span' ||
        node.name === 'strong'
      )
    },
    processNode: function (node: any, children: any[]) {
      let childs = extractChildText(node.children)

      if (node.name === 'h1' && childs && !ref.title) {
        ref.title = true
        return React.createElement(
          Header,
          // @ts-ignore
          { key: childs, id, children: [] },
          childs
        )
      } else if (
        (node.name === 'p' || node.name === 'span') &&
        !ref.p &&
        childs.length > 20
      ) {
        ref.p = true

        childs = childs.slice(0, 180) + '...'
        return React.createElement(
          Paragraph,
          // @ts-ignore
          { key: childs, children: [] },
          childs
        )
      }
      return children
    }
  }
]

export const processContent = (id: number, html: string) => {
  const htmlToReactParser = new HtmlToReact.Parser()
  const htmlInput = html || ''
  const isValidNode = () => {
    return true
  }
  ref = {}
  return htmlToReactParser.parseWithInstructions(
    htmlInput,
    isValidNode,
    processingInstructions(id)
  )
}
