// @ts-nocheck
import React, { useCallback, useEffect, useRef, useState } from 'react'
import moment from 'moment'
import SimpleBar from 'simplebar-react'
import cx from 'classnames'
import {
  ArticleContainer,
  Container,
  DownloadLink,
  FooterText
} from './Article.style'
import { Spinner } from 'c2-uikit'
import { copyright } from '../../utils'
import { ReactComponent as Close } from '../../assets/close.svg'
import { ReactComponent as DownloadSVG } from '../../assets/download-24.svg'
import { useArticle } from '../../hooks/apiHooks'
import { useCMSDispatch, useCMSState } from '../../hooks'
import useArticleLink from '../../hooks/useArticleLink'
import { WIDGET_SEARCH } from '../../context/cms.actions'
import useLinkTracking from '../../hooks/useLinkTracking'
import useFeedback from '../../hooks/api/useFeedback'

const videoBeingPlayed = () => {
  const videos = document.getElementsByTagName('video')
  for (let i = 0; i < videos.length; i++) {
    if (!videos[i].paused) {
      return true
    }
  }
  return false
}

const CHECK_CONTENT_EVERY_MS = 5000

let timer = 0

const formatMathContent = () => {
  if (window.MathJax) {
    window.MathJax.Hub.Queue([
      'Typeset',

      // @ts-ignore
      window.MathJax.Hub,
      document.getElementById('cybercube-content')
    ])
  }
}

const ArticleRenderer: React.FC = () => {
  const scrollRef = useRef<SimpleBar>(null)
  const articleRef = useRef<HTMLDivElement>(null)
  const [showPreview, setShowPreview] = useState<undefined | string>(undefined)
  const { widget, core } = useCMSState()
  const { app, sendToCmsIFrame } = core
  const articleLink = useArticleLink()
  const { categorySlug, rootId, rootSlug, articleSlug } =
    widget.articleOptions || {}
  const dispatch = useCMSDispatch()
  const { data, error, revalidate, lastFetched } = useArticle({
    category: categorySlug || rootSlug,
    article: articleSlug,
    rootId: String(rootId)
  })
  const loading = !data && !error
  const article = data && data.data
  useEffect(() => {
    const content = document.getElementsByClassName('ck-content')
    if (content.length) {
      const images = content[0].getElementsByTagName('img')
      for (let i = 0; i < images.length; i++) {
        const img = images[i]
        img.style.cursor = 'pointer'
        img.onclick = () => {
          setShowPreview(img.src)
        }
      }
    }
    formatMathContent()
  }, [article])

  const downloadPDF = useCallback(
    (e) => {
      if (!article?.allow_pdf || !article?.pdf_link) {
        e.preventDefault()
        e.stopPropagation()
      }
    },
    [article]
  )

  useEffect(() => {
    timer = setInterval(() => {
      if (!videoBeingPlayed()) {
        if (moment(lastFetched).add(300, 'seconds').isBefore(moment())) {
          revalidate()
        }
      }
    }, CHECK_CONTENT_EVERY_MS)

    if (scrollRef.current) {
      // @ts-ignore
      const scrollEl = scrollRef.current.getScrollElement()
      scrollEl.scrollTop = 0
    }

    return () => {
      if (timer) {
        clearInterval(timer)
        timer = 0
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastFetched, revalidate, sendToCmsIFrame])

  useEffect(() => {
    if (
      article &&
      articleLink &&
      article.data &&
      (article.data.openapi || article.data.treeMap)
    ) {
      dispatch({ type: WIDGET_SEARCH })
      window.open(articleLink, '_blank')
    }
  }, [dispatch, article, articleLink])

  const jsonData = article?.data
  const footerText = (
    <FooterText className='article-footer' data-test-id='article-page-footer'>
      {copyright}
    </FooterText>
  )

  const handleTrackLinkClick = useCallback(
    (link) => {
      if (sendToCmsIFrame) {
        sendToCmsIFrame({
          type: 'track',
          category: 'Widget',
          action: `${app}_widget-click-link`,
          label: link
        })
      }
    },
    [app, sendToCmsIFrame]
  )

  useEffect(() => {
    sendToCmsIFrame({
      type: 'track',
      category: 'Widget',
      action: `${app}_widget-view`,
      label: articleLink
    })
  }, [])

  useLinkTracking({
    containerIds: ['article-container'],
    onTrack: handleTrackLinkClick
  })

  const hasPDFDownload = article?.allow_pdf && article?.pdf_link

  const renderArticle = (
    <div className={`article ${!hasPDFDownload ? 'no-pdf' : ''}`}>
      {hasPDFDownload && (
        <DownloadLink
          data-test-id='download-pdf'
          href={article?.pdf_link}
          target='_blank'
          disabled={!article?.pdf_link}
          onClick={downloadPDF}
        >
          <DownloadSVG />
          Download article
        </DownloadLink>
      )}
      {!jsonData?.openapi && (
        <div
          id='cybercube-content'
          className='ck-content ck-content-container'
          data-test-id='article-content'
        >
          {article && (
            <div
              dangerouslySetInnerHTML={{
                __html:
                  article.content &&
                  article.content.split('>&nbsp;<').join('><')
              }}
            />
          )}
        </div>
      )}

      {footerText}
    </div>
  )

  return (
    <Container id='article-container' data-test-id='documentation_container'>
      {showPreview && (
        <div
          className='preview-overlay'
          onClick={() => setShowPreview(undefined)}
        >
          <div className='preview' onClick={(e) => e.stopPropagation()}>
            <Close
              className='close'
              onClick={() => setShowPreview(undefined)}
            />
            <img src={showPreview} alt='preview' />
          </div>
        </div>
      )}
      <ArticleContainer
        ref={articleRef}
        className={cx({ loading, treeMap: article?.data?.treeMap })}
      >
        <SimpleBar ref={scrollRef} style={{ height: 'calc(100vh - 254px)' }}>
          <div className='with-panel'>
            {loading ? (
              <div className='article'>
                <div className='loading-overlay'>
                  <Spinner
                    className='spinner'
                    color='var(--cc-main)'
                    data-test-id='spinner'
                  />
                </div>
              </div>
            ) : (
              renderArticle
            )}
          </div>
        </SimpleBar>
      </ArticleContainer>
    </Container>
  )
}

export default ArticleRenderer
