import axios from 'axios'
import checkSession from './checkSession'

const Common = require('c2-common-ui')
const { Session } = Common

const createApi = (baseURL: string) => {
  const api = axios.create({
    baseURL,
    headers: {
      Authorization: sessionStorage.getItem('id_token')
    }
  })

  api.interceptors.response.use(
    (res) => {
      return res
    },
    (error) => {
      const originalRequest = error.config

      if (
        (!error.response ||
          [401, 403, 500].indexOf(error.response.status) >= 0) &&
        !originalRequest.retry &&
        !Session.isIdTokenValid() &&
        originalRequest.url.indexOf('/refresh') < 0
      ) {
        originalRequest.retry = true
        return checkSession().then(() => {
          originalRequest.headers = {
            Authorization: sessionStorage.getItem('id_token')
          }
          return api(originalRequest)
        })
      }
      return error
    }
  )
  return api
}

export { createApi }
