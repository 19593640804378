import { useCallback, useEffect } from 'react'

export type UseLinkTrackingParams = {
  containerIds?: string[]
  onTrack: (link: string) => void
  history?: any
}

const useLinkTracking: (params: UseLinkTrackingParams) => void = ({
  history,
  onTrack,
  containerIds
}) => {
  const handleElementClick = useCallback(
    (e) => {
      let target = e.target
      while (target && target.tagName !== 'A') {
        target = target.parentNode
        if (!target) {
          return
        }
      }
      if (
        target &&
        target.tagName === 'A' &&
        !target.attributes.disabled &&
        target.href
      ) {
        e.preventDefault()
        const relativePath = target.href.replace(window.location.href, '')
        if (relativePath.indexOf('#') === 0) {
          const el = document.getElementById(relativePath.replace('#/', ''))
          if (el) {
            el.scrollIntoView({ behavior: 'smooth', block: 'center' })
          }
        } else {
          if (onTrack) {
            onTrack(target.href)
          }
          if (target.href.startsWith(window.location.origin) && history) {
            history.push({
              pathname: target.href.replace(window.location.origin, ''),
              search: history.location.search
            })
          } else {
            window.open(target.href, '_blank')
          }
        }
      }
    },
    [history]
  )
  useEffect(() => {
    if (containerIds) {
      containerIds.forEach((containerId) => {
        const container = document.getElementById(containerId)
        if (container) {
          container.addEventListener('click', handleElementClick)
        }
      })
    }
    return () => {
      if (containerIds) {
        containerIds.forEach((containerId) => {
          const container = document.getElementById(containerId)
          if (container) {
            container.removeEventListener('click', handleElementClick)
          }
        })
      }
    }
  }, [handleElementClick, containerIds])
}

export default useLinkTracking
