import React from 'react'
import styles from './ExternalLink.style.css'
import { ReactComponent as ExternalIcon } from '../../assets/external-link.svg'

const ExternalLink: React.FC<{ href: string; target?: string }> = ({
  href,
  target,
  children
}) => (
  <a className={styles.externalLink} href={href} target={target || '_blank'}>
    {children} <ExternalIcon />
  </a>
)

export default ExternalLink
