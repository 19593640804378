import { useMemo } from 'react'
import { getArticleHistoryObject } from '../utils'
import useCMSState from './useCMSState'

const useArticleLink = () => {
  const { core, widget } = useCMSState()
  const { articleOptions } = widget

  return useMemo(() => {
    const articleHistoryObject = getArticleHistoryObject(articleOptions)
    if (articleHistoryObject && core.ui) {
      const lastChar = core.ui.charAt(core.ui.length - 1)
      return (
        (lastChar === '/' ? core.ui.substr(0, core.ui.length - 1) : core.ui) +
        articleHistoryObject.pathname +
        '?' +
        articleHistoryObject.search
      )
    }
    return ''
  }, [core.ui, articleOptions])
}

export default useArticleLink
