import React, { useCallback } from 'react'
import { NotificationModel } from '../../context/cms.reducer'
import { ReactComponent as Close } from '../../assets/close.svg'
import { useCMSDispatch, useCMSState } from '../../hooks'
import styles from './Notification.style.css'
import { NOTIFICATIONS_MARK_SEEN } from '../../context/cms.actions'

const { spring, Motion } = require('react-motion')

export type NotificationProps = {
  className?: string
  notification: NotificationModel
  revalidate?: any
  onClick?: (action: string, label?: string) => void
}

const Notification: React.FC<NotificationProps> = ({
  className,
  notification,
  revalidate,
  onClick
}) => {
  const { id, link, text, linkText } = notification
  const state = useCMSState()
  const dispatch = useCMSDispatch()
  const markAsSeen = useCallback(() => {
    dispatch({ type: NOTIFICATIONS_MARK_SEEN, value: [id] })
    revalidate()
  }, [dispatch, id])
  const handleOpen = useCallback(
    (e: any) => {
      if (onClick) {
        onClick(
          'NOTIFICATION_LINK_OPEN',
          e.target ? (e.target as HTMLLinkElement)?.href : 'Unknown link'
        )
      }
      markAsSeen()
    },
    [markAsSeen, onClick]
  )
  const handleClose = useCallback(() => {
    if (onClick) {
      onClick('NOTIFICATION_CLICK_CLOSE')
    }
    markAsSeen()
  }, [markAsSeen, onClick])

  return text ? (
    <Motion
      defaultStyle={{ right: -800, opacity: 0 }}
      style={{ right: spring(10), opacity: spring(1) }}
    >
      {(val: any) => (
        <div
          className={`${styles.notification} ${className || ''}`}
          style={val}
        >
          <div className={styles.newLabel}>NEW</div>
          <div className={styles.notificationContent}>{text}</div>
          {linkText && link && (
            <a
              onClick={handleOpen}
              className={styles.notificationLink}
              href={`${state.core.ui}${link}`}
              target='_blank'
              rel='noreferrer'
            >
              {linkText}
            </a>
          )}
          <div className={styles.icon}>
            <Close onClick={handleClose} />
          </div>
        </div>
      )}
    </Motion>
  ) : (
    <span />
  )
}

export default Notification
