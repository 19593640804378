;(function () {
  const scriptTex = document.createElement('script')
  scriptTex.setAttribute('type', 'text/x-mathjax-config')
  scriptTex.innerHTML = `
      window.MathJax.Hub.Config({
        showMathMenu: false,
        messageStyle: 'none'
      })
      window.MathJax.Hub.setRenderer('SVG')
    `

  const script = document.createElement('script')
  script.src =
    'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.5/MathJax.js?config=TeX-MML-AM_CHTML'

  document.head.appendChild(scriptTex)
  document.head.appendChild(script)
})()
