import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AppType } from '../context/cms.state'

export const inIframe = () => {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

const useCMSEventTransfer = ({ ui, app }: { ui: string; app: AppType }) => {
  const frameRef = useRef<HTMLIFrameElement | null>(null)
  const [showFrame, setShowFrame] = useState(false)

  const send = useCallback(
    (data) => {
      const currentFrame = frameRef && frameRef.current
      const frame = currentFrame ? currentFrame.contentWindow : window
      if (frame) {
        frame.postMessage(JSON.stringify(data), ui)
      }
    },
    [ui, frameRef]
  )

  useEffect(() => {
    setTimeout(() => {
      setShowFrame(true)
    })
    return () => {
      setShowFrame(false)
    }
  }, [])

  const notInIframe = !inIframe()

  return useMemo(
    () => ({
      send,
      notInIframe,
      frame: notInIframe && showFrame && window.origin !== ui && (
        <iframe
          title='cms-ui'
          ref={frameRef}
          style={{ display: 'none', visibility: 'hidden' }}
          id='cms-frame'
          src={`${ui}/widget/${app}`}
        />
      ),
      ref: frameRef
    }),
    [notInIframe, showFrame, send, frameRef, ui, app]
  )
}

export default useCMSEventTransfer
