import styled from 'styled-components';
import { ButtonProps } from './Button.types';

const getType = ({ buttonType = 'base' }) => types[buttonType];

export const StyledButton = styled.button<ButtonProps>`
    ${getType}
    color: ${({ textColor = '' }: ButtonProps) => textColor};
    background-color: ${({ backgroundColor = '' }: ButtonProps) =>
        backgroundColor};
    width: ${({ width = '' }: ButtonProps) => width};
    height: ${({ height = '40px' }: ButtonProps) => height};
    padding: 12px 16px;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: 0.4px;
`;

const types = {
    base: `
    color: #ffffff;
    background-color: #0e8fc9;
        :disabled {
            background-color: #7a7a7a;
            opacity: 0.2;
            pointer-events: none;
        }

        :hover {
            background-color: #1badef;
        }

        :active {
            background-color: #0b6d99;
            color: #ffffff;
        }

        :focus {
            outline: 0;
        }
    `,
    link: `
        background-color: #fff;
        color: #0b74a3;
        :disabled {
            color: rgba(82, 82, 82, 0.2);
            background-color: rgba(0, 0, 0, 0.0);
            pointer-events: none;
        }

        :hover {
            color: #1badef;
            background-color: rgba(0, 0, 0, 0.04);
            // opacity: 0.04
        }

        :active {
            color: #0b6d99;
            background-color: rgba(0, 0, 0, 0.08);
        }

        :focus {
            outline: 0;
        }
    `,
};
